import React, { useEffect } from 'react';
import { Formulario } from "../../pages/Formulario";
import TelaInicial from "../../pages/TelaInicial";
import TelaProtocolo from "../../pages/TelaProtocolo";
import Styles from "./styles.module.scss";
import LogoHavan from "../../assets/logoHavan.svg";
import LogoHavanLabs from "../../assets/HavanLabs.svg";
import { PagesPPR, usePagesContext } from '../../contexts/PagesPPR';
import Acompanhamento from '../../pages/Acompanhamento';
import ReenvioProtocolo from '../../pages/ReenvioProtocolo';
import { InformesRendimento } from '../../pages/InformesRendimento';
import { useForm } from '../../hooks/form';

function Main() {
    const PagesContext = usePagesContext();
    const { setCurrentStep } = useForm();

    function CheckLogin() {
        const url = new URLSearchParams(window.location.search);
        if (url.has("access_token")) {
            PagesContext.setFuncionarioLogado(true);
            PagesContext.changeTipoSolicitante(2);
            setCurrentStep(1);
            PagesContext.changePage(PagesPPR.NovaSolicitacao);
        }
    }

    useEffect(() => {
        CheckLogin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={`${Styles.mainContent}`}>
            <div className="container">
                <div className={Styles.main}>
                    <header>
                        <img
                            id="logoHavan"
                            src={LogoHavan}
                            width={100}
                            height={80}
                            alt="Havan"
                            onClick={() => PagesContext.changePage(PagesPPR.Inicio)}
                            className={Styles.logoHavan}
                        />

                        {/* <img 
                            id="logoHavan"
                            src={LogoRH}
                            width={150} 
                            height={150} 
                            alt="RH" 
                        /> */}
                    </header>
                    {(PagesContext.page === PagesPPR.Inicio) && <TelaInicial />}
                    {(PagesContext.page === PagesPPR.NovaSolicitacao) && <Formulario />}
                    {(PagesContext.page === PagesPPR.TelaDeProtocolo) && <TelaProtocolo />}
                    {(PagesContext.page === PagesPPR.AcompanharSolicitacao) && <Acompanhamento />}
                    {(PagesContext.page === PagesPPR.ReenvioDeProtocolo) && <ReenvioProtocolo />}
                    {(PagesContext.page === PagesPPR.InformesRendimento) && <InformesRendimento />}
                    <footer>
                        <img src={LogoHavanLabs} width={95} height={40} alt="Havan" className={Styles.logoHavanLabs} />
                        <p>Copyright 2021 - Todos os direitos reservados - Havan Labs</p>
                    </footer>
                </div>
            </div>
        </div>
    )
}

export default Main
