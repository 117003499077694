import Confirmacao from './ConfirmacaoIrrf';
import ExColaborador from '../SolicitacaoReutilizavel/ExColaboradorForm';
import Styles from "../SolicitacaoReutilizavel/styles.module.scss";
import { useState } from 'react';
import {  IDadosIdentificacao } from '../../interfaces/IPPRInativoSolicitacao';
import { useForm } from '../../hooks/form';
import { EnumTipoSolicitacao } from '../../enums/EnumTipoSolicitacao';

export const InformesRendimento: React.FC = () => {

    const { step } = useForm();

    const [formularioIdentificacao, setFormularioIdentificacao] = useState<IDadosIdentificacao | null>(null);

    function PreencheIdentificacao(dados: IDadosIdentificacao) {
        setFormularioIdentificacao(dados)
    }

    return (
        <div className={`${Styles.formulario}`}>
            {step === 0 && <ExColaborador formulario={formularioIdentificacao} preencheFormulario={PreencheIdentificacao} tipoSolicitacao={EnumTipoSolicitacao.INFORME_DE_RENDIMENTOS}/>}
            {step === 1 && <Confirmacao formulario={formularioIdentificacao} />}
        </div>
    );
};
