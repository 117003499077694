import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  height: 100%;
`;
