import { ValidateCPF as CPFValidator } from '@havan/fit-components';
import { ITipoChavePix } from '../interfaces/IPPRInativoSolicitacao';
import ISelectOptions from '../interfaces/ISelectOptions';

export function ValidateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const ValidatePhone = (telefone: string) => {
    if (telefone.length < 15) {
        return false;
    }
    var SPHONE = telefone.substring(5, 15)
    if (SPHONE.replace(/\D/g, '').split("").every(n => n === "0") ||
        SPHONE.replace(/\D/g, '').split("").every(n => n === "1") ||
        SPHONE.replace(/\D/g, '').split("").every(n => n === "2") ||
        SPHONE.replace(/\D/g, '').split("").every(n => n === "3") ||
        SPHONE.replace(/\D/g, '').split("").every(n => n === "4") ||
        SPHONE.replace(/\D/g, '').split("").every(n => n === "5") ||
        SPHONE.replace(/\D/g, '').split("").every(n => n === "6") ||
        SPHONE.replace(/\D/g, '').split("").every(n => n === "7") ||
        SPHONE.replace(/\D/g, '').split("").every(n => n === "8") ||
        SPHONE.replace(/\D/g, '').split("").every(n => n === "9"))
        return false;
    var mask = /^\([1-9]{2}\) 9[1-9][0-9]{3}\-[0-9]{4}$/
    return mask.test(telefone)
};

export const ValidateCPF = (CPF: string) => {
    return CPFValidator(CPF).isValid;
}

function apenasCaracteresIguais(texto: string) {
    return texto.replace(/\s/g, '').split('').every(function (num, i, arr) { return num === arr[0] });
}

export function validarNome(nome: string, nomeMin: number = 0, nomeMax: number = 250) {
    return !((nome.trim().split(" ").length <= 1) ||
        (nome.trim().length <= nomeMin || nome.trim().length > nomeMax) || apenasCaracteresIguais(nome))
}

export function validarNomeMae(nome: string, nomeMin: number = 0, nomeMax: number = 250) {
    return !((nome.trim().length <= nomeMin || nome.trim().length > nomeMax) || apenasCaracteresIguais(nome))
}

export function validaAgencia(agencia: string) {
    return agencia.length >= 1 && agencia.length <= 5 && !agencia.split("").every(c => c === "0");
}

export function validaNumeroConta(numeroConta: string) {
    return (numeroConta.length >= 1 && numeroConta.length <= 12) && !numeroConta.split("").every(c => c === "0");
}

export function validaMensagem(mensagem: string, mensagemMin: number = 0, mensagemMax: number = 250) {
    return !(mensagem.trim().length <= mensagemMin || mensagem.trim().length > mensagemMax)
}

export function validaChaveAleatoriaPix(chave: string) {
    return !!(chave)
}

export function validaChavePix(chavePix: string, tipoChave: ITipoChavePix) {
    if (chavePix) {
        switch (tipoChave) {
            case 1: return ValidateCPF(chavePix) 
            case 2: return ValidatePhone(chavePix);
            case 3: return ValidateEmail(chavePix);
            case 4: return validaChaveAleatoriaPix(chavePix);
            default: return true
        }
    }
    else return true
}

export function validarUnidadeNegocio(unidadeNegocio: ISelectOptions | null) {
    return !!(unidadeNegocio)
}

export function validarUltimoCargo(ultimoCargo: ISelectOptions | null) {
    return !!(ultimoCargo)
}