import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle as MuiDialogTitle, DialogContent, IconButton, } from "@material-ui/core";
import { FaTimes } from "react-icons/fa";
import Styles from './styles.module.scss';
import api from '../../services/api';
import { geraNotificacao } from '../../utils/funcoesNotificacao';
import { DotsLoader } from "../Loading"
import Ajuste from './ajuste';
import { formataCPF} from '../../utils/formataCampos';
interface IDialogHeader {
    title?: string;
    onClose?: () => void;
}

const DialogHeader: React.FC<IDialogHeader> = ({ title, onClose }) => {
    return (
        <>
            <MuiDialogTitle disableTypography className={Styles.dialogModalHeader} >
                <div className={Styles.dialogModalText}>{title}</div>
                {onClose ? (
                    <IconButton aria-label="close" className={Styles.dialogModalButton} onClick={onClose}>
                        <FaTimes />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        </>
    )
}

interface IEtapasModal {
    open: boolean;
    onClose: () => void;
    closable?: boolean;
    title?: string;
    protocolo: number;
}

interface IEtapa {
    Id: number;
    Nome: string;
    Ordem: number;
    Cor: string;
    Descricao: string;
}

interface IBanco {
    Id: number,
    CodFebraban: number,
    Nome: string,
    RequerOperacao: boolean,
}

export interface IPPRInativoRequisicao {
    Id: number;
    Protocolo: number;
    NomeColaborador: string;
    CpfColaborador: string;
    DataSolicitacao: string;
    TipoSolicitante: number;
    Banco: IBanco;
    Agencia: string;
    DigitoAgencia: string;
    TipoChavePix: number | null;
    ChavePix: string | null;
    Situacao: number;
    SolicitanteCPF: string;
    SolicitanteNome: string;
    SolicitanteEmail: string;
    SolicitanteTelefone: string;
    TipoConfirmacao: string;
    TipoConta: number;
    NumeroConta: number;
    DigitoConta: string;
    Operacao: number;
    TipoContato: string;
    RepresentadoCPF: string | null;
    RepresentadoNome: string | null;
    RepresentadoEmail: string | null;
    RepresentadoTelefone: string | null;
    ConjugeCompanheiro: boolean | null;
    HerdeiroDescendente: boolean | null;
    SemRelacao: boolean | null;
    TitularFalecido: boolean | null;
    Etapa: number;
    DataBeneficio: string | null;
    NomeMaeRepresentado: string;
    UltimaUnidadeNegocio: number;
    UltimoCargo: number;
    OpcaoRecebimentoPIX: number | null; 
    ChavePIX: string | null;   
}

export interface IRequisicaoAjuste {
    Id: number;
    Solicitacao: number;
    NomeRepresentante: boolean,
    CpfRepresentante: boolean,
    EmailRepresentante: boolean,
    TelefoneRepresentante: boolean,
    NomeExColaborador: boolean,
    EmailExColaborador: boolean,
    TelefoneExColaborador: boolean,
    ReenviarAnexoExColaborador: boolean,
    Banco: boolean,
    Agencia: boolean,
    TipoDeConta: boolean,
    NumeroDaConta: boolean,
    ChavePix: boolean,
    ReenviarAnexoBancario: boolean,
    Mensagem: string
}


const EtapasModal: React.FC<IEtapasModal> = ({ open, closable, onClose, title, protocolo, children }) => {

    const hasHeader = (title || closable);

    const [etapaAtual, setEtapaAtual] = useState<IEtapa>();
    const [carregandoEtapas, setCarregandoEtapas] = useState(false);
    const [solicitacao, setSolicitacao] = useState<IPPRInativoRequisicao | null>(null);
    const [solicitacaoAjuste, setSolicitacaoAjuste] = useState<IRequisicaoAjuste | null>(null);
    const [carregadoPdf,setCarregandoPdf] = useState(false);
    const [cpfSolicitacao, setCpfSolicitacao] = useState<String >("");

    async function fetchEtapas() {
        setCarregandoEtapas(true);

        await api.get(`/api/Solicitacao/GetEtapaSolicitacao?protocolo=${protocolo}`)
            .then((res: any) => {
                setEtapaAtual(res.data);
            })
            .finally(() => setCarregandoEtapas(false))
    }

    /*async function fetchSolicitacao() {
        await api.get(`/api/Solicitacao/GetSolicitacaoPorProtocolo?protocolo=${protocolo}`)
            .then((res: any) => {
                if (res.data) {
                    setSolicitacao(res.data);
                }
            })
    }*/

    async function fetchSolicitacaoAjuste() {
        if (solicitacao) {
            await api.get(`/api/RequisicaoAjusteSolicitacao/GetRequisicaoAjusteSolicitacao?id=${solicitacao.Id}`)
                .then((res: any) => {
                    if (res.data) {
                        setSolicitacaoAjuste(res.data);
                    }
                })
        }
    }

    async function fetchConsultaDemonstrativo() {
        setCarregandoPdf(true)
        await api.get(`/api/Solicitacao/ObterDemonstrativo?protocolo=${protocolo}&cpf=${cpfSolicitacao.replace(/[^\d]+/g,'')}`)
            .then((res: any) => {
                if(res.data){
                    const binaryString = atob(res.data)
                    var bytes = new Uint8Array(binaryString.length);
                    for (var i = 0; i < binaryString.length; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }  
                    const blob =  new Blob([bytes], { type: 'application/pdf' });
                    window.open(URL.createObjectURL(blob));
                }
            }
            ).catch((erro : any) => {
                let msg = erro.response.data
                if(msg ==  "cálculo não encontrado!")
                {
                    msg = "Demonstrativo não está disponível";
                }

                geraNotificacao({
                    tipo: "customizado",
                    mensagem:  msg,
                    severity: "error"
                }); 
            }
            ).finally(() => {
                setCarregandoPdf(false);
            })
        }

    useEffect(() => {
        fetchSolicitacaoAjuste();
    }, [solicitacao]);

    useEffect(() => {
        if (open) {
            if (protocolo && String(protocolo).length === 9) {
                fetchEtapas();
                //fetchSolicitacao();
            }
        }

    }, [protocolo, open]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth className={Styles.dialogModal}>
            {hasHeader && <DialogHeader title={title} onClose={closable ? onClose : undefined} />}
            <DialogContent className={Styles.dialogModalContent}>

                <div className={Styles.protocoloContainer}>
                    <p>Protocolo da sua Solicitação:</p>
                    <span>{protocolo}</span>
                </div>
                <h1>Status da Solicitação: <span style={{ color: etapaAtual?.Cor }}>{etapaAtual?.Nome}</span></h1>
                {carregandoEtapas &&
                    <div className="d-flex justify-content-center">
                        <DotsLoader isLoading />
                    </div>
                }

                {
                    (solicitacaoAjuste && etapaAtual?.Nome === "Correção solicitada")
                        ? <Ajuste FetchEtapa={fetchEtapas} Solicitacao={solicitacao} SolicitacaoAjuste={solicitacaoAjuste} />
                        : <footer>
                            <p className={Styles.etapaAtualDescricao}>{etapaAtual?.Descricao}</p>
                        </footer>
                }
                <div className={Styles.dadosLabel}>
                    <div className={Styles.dadosLabel}>
                        <input 
                            placeholder="CPF do Solicitante."
                            maxLength={14}
                            value={String(cpfSolicitacao)}
                            onChange={(data) => setCpfSolicitacao(formataCPF(data.target.value))}
                        />  
                    </div>
                    <div  className={Styles.dadosLabel}>
                        <button  disabled={!(cpfSolicitacao.length == 14) } className="btn btn-primary" onClick={() => fetchConsultaDemonstrativo()}>Consulta demonstrativo</button>
                    </div>
                </div>
               {carregadoPdf && 
                    <div className="d-flex justify-content-center">
                        <DotsLoader isLoading />
                    </div>
               }

            </DialogContent>
        </Dialog>
    )
}

export default EtapasModal
