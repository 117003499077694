import React, { useEffect, useState } from 'react'
import { geraNotificacao } from '../../utils/funcoesNotificacao';
import Modal from "../CustomModal";
import LoadingModal from '../LoadingModal';
import Input from "./inputCodigo";
import "./styles.css";

interface CodigoVerificacaoProps {
    open: boolean;
    closeModal: () => void;
    submit: (codigo: string) => Promise<void>;
    reenviarCodigo: () => void;
    formaContato: string;
    contato: string;
}


const CodigoVrificacao: React.FC<CodigoVerificacaoProps> = ({ open, closeModal, submit, reenviarCodigo, formaContato, contato }) => {
    let intervalo: any = undefined;
    const [minutos, setMinutos] = useState(2);
    const [segundos, setSegundos] = useState(0);
    const [carregandoModal, setCarregandoModal] = useState(false);

    function voltar() {
        closeModal();
    }

    async function continuar(codigo: string) {
        setCarregandoModal(true);
        await submit(codigo);
        setCarregandoModal(false);
    }

    const censuraContato = (contatoCen: string) => {
        let palavra = Array.from(contatoCen)
        let inicio = 1;
        let fim = palavra.length - 8;
        let asteriscos = Array((palavra.length)).fill("*");
        switch (formaContato) {
            case "telefone": inicio = 7; break;
            case "email": fim = palavra.length - 5; break;
        }

        palavra.splice(inicio, fim, asteriscos.splice(inicio, fim).join(""))        
        return palavra.join("")
    }

    const resetTimer = () => {
        let segundosTime = 0;
        let minutosTime = 2;

        if (intervalo)
            clearInterval(intervalo);

        intervalo = setInterval(() => {
            if (segundosTime > 0) {
                segundosTime = segundosTime - 1;
            }

            if (segundosTime === 0) {
                if (minutosTime === 0) {
                    clearInterval(intervalo)
                } else {
                    minutosTime = minutosTime - 1;
                    segundosTime = 59;
                }
            }
            setMinutos(minutosTime)
            setSegundos(segundosTime);
        }, 1000)
    }

    const ReenviarCodigo = () => {
        if (minutos === 0 && segundos === 0) {
            //TODO: solicitar o reenvio do codigo
            resetTimer();
            reenviarCodigo();
        }
        else {
            geraNotificacao({
                tipo: "customizado",
                mensagem: "O código só pode ser reenviado ao acabar o tempo.",
                severity: "error"
            });
        }
    }

    useEffect(() => {
        if (open) {
            resetTimer()
            return () => {
                clearInterval(intervalo);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <>
            <Modal open={open} onClose={voltar} size={'lg'} closable>
                <h3 className="verification-label">Código de Confirmação</h3>
                <p className="verification-p">Digite aqui o código enviado para o seu {formaContato}</p>
                <p className="verification-p">{censuraContato(contato)}</p>
                <Input
                    length={6}
                    label=""
                    loading={false}
                    onComplete={continuar}
                />
                <button type="button" className="custom-btn-link btn-link" onClick={ReenviarCodigo}>Reenviar Codigo</button>
                {(minutos !== 0 || segundos !== 0) &&
                    <p><small>Aguarde {minutos.toString().padStart(2, "0")}:{segundos.toString().padStart(2, "0")} para reenviar</small></p>
                }
            </Modal>
            <LoadingModal isLoading={carregandoModal} />
        </>
    )
}


export default CodigoVrificacao;
