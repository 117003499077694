import React, { ReactNode } from "react";
import { IconBaseProps } from "react-icons";
import { ProgressBar } from "./ProgressBar";
import { FormStepProps } from "../FormStep";
import { Container, Content, FormContent } from "./styles";
import { useForm } from "../../../hooks/form";
import { FaCheck } from "react-icons/fa";

type FormStepperProps = {
  children: ReactNode;
  progressIcons?: React.ComponentType<IconBaseProps>[];
  labels?: string[];
};

export const FormStepper: React.FC<FormStepperProps> = ({
  children,
  labels = [],
}) => {
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<FormStepProps>[];

  const { step } = useForm();

  return (
    <Container>
      <ProgressBar currentStep={step} labels={labels}>
        {labels.map((value, index) => (
          <div key={value}>{step > index ? <FaCheck /> : ""}</div>
        ))}
      </ProgressBar>
      <Content>
        <FormContent>{childrenArray[step]}</FormContent>
      </Content>
    </Container>
  );
};
