import { useDropzone } from 'react-dropzone';
import { FaGreaterThan } from 'react-icons/fa';
import Select from 'react-select';
import { useEffect, useState } from 'react';

import ISelectOptions from '../../interfaces/ISelectOptions';
import { IPPRInativoRequisicao, IRequisicaoAjuste } from './index';
import api from '../../services/api';
import { geraNotificacao } from '../../utils/funcoesNotificacao';
import Collapse from 'react-bootstrap/Collapse'

import Styles from './styles.module.scss';
import LoadingModal from '../LoadingModal';
import DocumentImg from '../../assets/document.svg'
import { formataCPF, formataNome, formataTelefone, formataAgencia, formataChavePix, formataDigito, formataNumeroConta } from '../../utils/formataCampos';
import { validaAgencia, validaNumeroConta, validaChavePix, validarNome, ValidateCPF, ValidateEmail, ValidatePhone } from '../../utils/validaCampos';
import { tiposChavePix } from '../../interfaces/IPPRInativoSolicitacao';

interface IAjustes {
  FetchEtapa: () => void;
  Solicitacao: IPPRInativoRequisicao | null;
  SolicitacaoAjuste: IRequisicaoAjuste | null;
}

interface IsValidCpf {
  isValid: boolean;
  error?: string;
}

const Ajuste: React.FC<IAjustes> = ({ Solicitacao, SolicitacaoAjuste, FetchEtapa }) => {
  const [representanteNome, setRepresentanteNome] = useState("");
  const [representanteNomeIsValid, setRepresentanteNomeIsValid] = useState<boolean>(true);

  const [representanteCPF, setRepresentanteCPF] = useState("");
  const [representanteCPFIsValid, setRepresentanteCPFIsValid] = useState<IsValidCpf>();

  const [representanteEmail, setRepresentanteEmail] = useState("");
  const [representanteEmailIsValid, setRepresentanteEmailIsValid] = useState<boolean>(true);

  const [representanteTelefone, setRepresentanteTelefone] = useState("");
  const [representanteTelefoneIsValid, setRepresentanteTelefoneIsValid] = useState(true);

  const [exColaboradorNome, setExColaboradorNome] = useState("");
  const [exColaboradorNomeIsValid, setExColaboradorNomeIsValid] = useState<boolean>(true);

  const [exColaboradorEmail, setExColaboradorEmail] = useState("");
  const [exColaboradorEmailIsValid, setExColaboradorEmailIsValid] = useState<boolean>(true);

  const [exColaboradorTelefone, setExColaboradorTelefone] = useState("");
  const [exColaboradorTelefoneIsValid, setExColaboradorTelefoneIsValid] = useState(true);

  const [banco, setBanco] = useState<ISelectOptions | null>(null);
  const [tipoConta, setTipoConta] = useState<ISelectOptions | null>(null);
  const tiposConta: ISelectOptions[] = [{ label: "Conta Corrente", value: 1 }, { label: "Conta Poupança", value: 2 }];
  const [numeroConta, setNumeroConta] = useState("");
  const [numeroDigito, setNumeroDigito] = useState("");
  const [agencia, setAgencia] = useState("");
  const [agenciaDigito, setAgenciaDigito] = useState("");
  const [tipoChavePix, setTipoChavePix] = useState<ISelectOptions | null>(null);
  const [chavePix, setChavePix] = useState("");

  const [bancos, setBancos] = useState<ISelectOptions[]>([]);
  const [carregandoBancos, setCarregandoBancos] = useState(false);

  const [agenciaIsValid, setAgenciaIsValid] = useState(true);
  const [numeroContaIsValid, setNumeroContaIsValid] = useState(true);
  const [numeroDigitoIsValid, setNumeroDigitoIsValid] = useState(true);
  const [chavePixIsValid, setChavePixIsValid] = useState(true);
  const [tipoContaIsValid, setTipoContaIsValid] = useState(true);
  const [bancoIsValid, setBancoIsValid] = useState(true);
  const nomeMaxLength = 100;

  const [openExColaborador, setOpenExColaborador] = useState(true);
  const [openRepresentante, setOpenRepresentante] = useState(true);
  const [openDadosBancarios, setOpenDadosBancarios] = useState(true);

  const [files, setFiles] = useState<any>([]);

  const [salvandoAjuste, setSalvandoAJuste] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: any) => {
      let arquivos_iguais: string[] = [];
      let arquivos_para_inserir: any[] = [];

      acceptedFiles.forEach((newFile: any) => {// Para cada arquivo enviado
        let jainserido = false;
        files.forEach((anexo: any) => {// Para cada arquivo já anexo
          jainserido = anexo.path === newFile.path;// Verifica se é o mesmo e guarda o valor em variavel
        });
        // O valor é guardado em variavel para poder verificar fora do loop de "anexos.forEach",
        // no caso de "anexos" não conter nenhum arquivo ainda.
        if (jainserido) {
          if (!arquivos_iguais.includes(newFile.path)) {
            arquivos_iguais.push(newFile.path)
          }
        }
        else {
          arquivos_para_inserir.push(newFile)
        }
      });

      if (arquivos_iguais.length > 0) {
        geraNotificacao({ tipo: "customizado", mensagem: `Os seguintes anexos já foram inseridos:\n\t${arquivos_iguais.join("\n\t")}` });
      }

      setFiles([...files, ...arquivos_para_inserir.map((file: any) => {

        if (file.type.includes("text/"))
          return Object.assign(file, {
            preview: DocumentImg
          });

        return Object.assign(file, {
          preview: URL.createObjectURL(file)
        });
      })]);
    }
  });


  function removeArquivo(indexArquivo: number) {
    var arquivos = files;
    arquivos.splice(indexArquivo, 1);

    setFiles([...arquivos]);
  }

  const preview = files.map((file: any, fileIdx: number) => (
    <div className={Styles.thumb} key={file.name}>
      <div className={Styles.thumbInner}>
        <img
          src={file.preview}
          title={file.path}
          alt={file.path}
          className={Styles.img}
        />
        <p title={file.path} className={Styles.fileName}>{file.path}</p>
        <span
          className={Styles.removeFileText}
          onClick={() => removeArquivo(fileIdx)}
        >
          Remover
        </span>
      </div>
    </div>
  ));

  useEffect(() => {
    api.get("/api/Banco/GetBancos")
      .then((res: any) => {
        setBancos(res.data.map((b: any) => ({ label: b.Nome, value: b.Id, CodFebraban: b.CodFebraban, requerOperacao: b.RequerOperacao })))
      })
      .finally(() => {
        setCarregandoBancos(false);
      })
  }, []);

  function validarCampos() {
    var forms = document.querySelectorAll('.needs-validation')

    Array.prototype.slice.call(forms)
      .forEach(function (form) {
        form.addEventListener('submit', function (event: any) {
          event.preventDefault()
          event.stopPropagation()
          form.classList.add('was-validated')
        }, false)
      });

    var campos = document.querySelectorAll('.notValid');

    Array.prototype.slice.call(campos)
      .forEach(function (campo) {
        campo.classList.remove("notValid");
      });

    campos = document.querySelectorAll('.requiredValidation')

    Array.prototype.slice.call(campos)
      .forEach(function (campo) {
        if (campo.value === undefined || campo.value === null || campo.value === "") {
          campo.classList.add("notValid");
        }
      });

    if (SolicitacaoAjuste?.TipoDeConta) {
      if (!tipoConta) {
        setTipoContaIsValid(false);
        return true;
      }
      setTipoContaIsValid(true);
    }

    if (SolicitacaoAjuste?.Banco) {
      if (!banco) {
        setBancoIsValid(false);
        return true;
      }
      setBancoIsValid(true);
    }

    const precisaAnexo = SolicitacaoAjuste?.ReenviarAnexoBancario || SolicitacaoAjuste?.ReenviarAnexoExColaborador;

    var invalidos = document.querySelectorAll('.notValid');

    return invalidos.length > 0 || (precisaAnexo && files.length <= 0);
  }

  function submit() {
    var camposInvalidos = validarCampos();

    if (document.querySelectorAll('.was-validated .form-control:invalid').length > 0 || camposInvalidos) {
      return;
    }

    const data = {
      Id: Solicitacao?.Id,
      TipoSolicitante: Solicitacao?.TipoSolicitante,
      SolicitanteCPF: "",
      SolicitanteNome: "",
      SolicitanteEmail: "",
      SolicitanteTelefone: "",
      TipoConfirmacao: Solicitacao?.TipoConfirmacao,
      Banco: banco?.value ?? Solicitacao?.Banco.Id,
      TipoConta: Solicitacao?.TipoConta,
      Agencia: +(agencia) !== 0 ? +(agencia) : Solicitacao?.Agencia,
      DigitoAgencia: (agenciaDigito !== null && agenciaDigito !== "") ? agenciaDigito : Solicitacao?.DigitoAgencia,
      NumeroConta: +(numeroConta) !== 0 ? (numeroConta) : Solicitacao?.NumeroConta,
      DigitoConta: (numeroDigito !== null && numeroDigito !== "") ? numeroDigito : Solicitacao?.DigitoConta,
      Operacao: Solicitacao?.Operacao ?? 0,
      TipoContato: Solicitacao?.TipoContato,
      RepresentadoCPF: Solicitacao?.RepresentadoCPF,
      RepresentadoNome: (Solicitacao?.TipoSolicitante === 2 && exColaboradorNome !== "") ? exColaboradorNome : Solicitacao?.RepresentadoNome,
      RepresentadoEmail: (Solicitacao?.TipoSolicitante === 2 && exColaboradorEmail !== "") ? exColaboradorEmail : Solicitacao?.RepresentadoEmail,
      RepresentadoTelefone: (Solicitacao?.TipoSolicitante === 2 && exColaboradorTelefone !== "") ? exColaboradorTelefone : Solicitacao?.RepresentadoTelefone,
      ConjugeCompanheiro: Solicitacao?.ConjugeCompanheiro,
      HerdeiroDescendente: Solicitacao?.HerdeiroDescendente,
      SemRelacao: Solicitacao?.SemRelacao,
      TitularFalecido: Solicitacao?.TitularFalecido || false,
      NomeMaeRepresentado: Solicitacao?.NomeMaeRepresentado,
      UltimaUnidadeNegocio: Solicitacao?.UltimaUnidadeNegocio,
      UltimoCargo: Solicitacao?.UltimoCargo,
      OpcaoRecebimentoPIX: tipoChavePix ? tipoChavePix.value : Solicitacao?.OpcaoRecebimentoPIX ?? 0,
      ChavePIX: chavePix !== "" ? chavePix : Solicitacao?.ChavePIX,
    }


    if (Solicitacao?.TipoSolicitante === 1) {
      data.SolicitanteCPF = Solicitacao?.SolicitanteCPF;
      data.SolicitanteNome = exColaboradorNome !== "" ? exColaboradorNome : Solicitacao?.SolicitanteNome;
      data.SolicitanteEmail = exColaboradorEmail !== "" ? exColaboradorEmail : Solicitacao?.SolicitanteEmail;
      data.SolicitanteTelefone = exColaboradorTelefone !== "" ? exColaboradorTelefone : Solicitacao?.SolicitanteTelefone;
    }


    if (Solicitacao?.TipoSolicitante === 2) {
      data.SolicitanteCPF = representanteCPF !== "" ? representanteCPF : Solicitacao?.SolicitanteCPF;
      data.SolicitanteNome = representanteNome !== "" ? representanteNome : Solicitacao?.SolicitanteNome;
      data.SolicitanteEmail = representanteEmail !== "" ? representanteEmail : Solicitacao?.SolicitanteEmail;
      data.SolicitanteTelefone = representanteTelefone !== "" ? representanteTelefone : Solicitacao?.SolicitanteTelefone;
    }

    const data_entries = Object.entries(data);

    console.log(data);
    console.log(data_entries);

    const formData = new FormData();

    data_entries.forEach((e) => {
      if (e[1] !== undefined && e[1] !== null && e[1] !== "undefined" && e[1] !== "null") {
        formData.append(e[0], e[1]);
      }
    })

    if (files.length) {
      files.forEach((anexo: any) =>
        formData.append("Anexos", anexo)
      )
    }

    setSalvandoAJuste(true);
    api.post("/api/Solicitacao/SalvarSolicitacao", formData)
      .then((res: any) => {
        setSalvandoAJuste(false);
        geraNotificacao({
          tipo: "customizado",
          mensagem: "Solicitação Salva com sucesso!",
          severity: "success"
        });
        FetchEtapa();
      })
      .catch((erro: any) => {
        setSalvandoAJuste(false);
        geraNotificacao({
          tipo: "customizado",
          mensagem: erro.response.data,
          severity: "error"
        });
      });
  }

  async function CpfOnBlur() {
    if (ValidateCPF(representanteCPF)) {
      setRepresentanteCPFIsValid({ isValid: true });
    }
    else {
      setRepresentanteCPFIsValid({ isValid: false, error: "CPF inválido" });
    }
  }

  const confirmacaoDadosPessoais = function() : any {
    if (Solicitacao?.TipoSolicitante === 1)
    {
      return compareDadosPessoais(SolicitacaoAjuste?.NomeExColaborador, (!!exColaboradorNome)) && 
              compareDadosPessoais(SolicitacaoAjuste?.TelefoneExColaborador, (!!exColaboradorNome && exColaboradorTelefoneIsValid)) && 
              compareDadosPessoais(SolicitacaoAjuste?.EmailExColaborador, (!!exColaboradorEmail)) &&
              compareDadosPessoais(SolicitacaoAjuste?.ReenviarAnexoExColaborador, (files.length > 0));
    } else {
      return  compareDadosPessoais(SolicitacaoAjuste?.NomeRepresentante, (!!representanteNome)) && 
              compareDadosPessoais(SolicitacaoAjuste?.TelefoneRepresentante, (!!representanteTelefone && representanteTelefoneIsValid)) &&
              compareDadosPessoais(SolicitacaoAjuste?.EmailRepresentante, (!!representanteEmail)) &&
              compareDadosPessoais(SolicitacaoAjuste?.CpfRepresentante, (!!representanteCPF)) &&
              compareDadosPessoais(SolicitacaoAjuste?.NomeExColaborador, (!!exColaboradorNome)) &&
              compareDadosPessoais(SolicitacaoAjuste?.TelefoneExColaborador, (!!exColaboradorTelefone && exColaboradorTelefoneIsValid)) &&
              compareDadosPessoais(SolicitacaoAjuste?.EmailExColaborador, (!!exColaboradorEmail)) &&
              compareDadosPessoais(SolicitacaoAjuste?.ReenviarAnexoExColaborador, (files.length > 0));
    }
  };

  const compareDadosPessoais = (efetuarComparacao: boolean | undefined, campo: boolean) : any => {
    if (!efetuarComparacao)
      return true;

    return campo;
  }

  const confirmacaoDadosBancarios = (
    (SolicitacaoAjuste?.TipoDeConta ? (!!tipoConta?.value) : true) &&
    (SolicitacaoAjuste?.Banco ? (!!banco?.value) : true) &&
    (SolicitacaoAjuste?.Agencia ? (!!agencia) : true) &&
    (SolicitacaoAjuste?.NumeroDaConta ? (!!numeroConta && !!numeroDigito) : true) &&
    (SolicitacaoAjuste?.ChavePix ? (!!chavePix && chavePixIsValid) : true) &&
    (SolicitacaoAjuste?.ReenviarAnexoBancario ? (files.length > 0) : true)
  );

  return (
    <>
      <form className="needs-validation" noValidate>
        <div>
          <header>
            <h1>Informe os dados para correção</h1>
          </header>
          <main>
            <div className={`row ${Styles.customBox}`} >
              <div className={`col-md-12 ${Styles.customForm}`}>

                <h2 className={Styles.tituloAjuste}>Ajustes requisitados pelo analista</h2>

                {Solicitacao?.TipoSolicitante === 1 ?
                  <>
                    {(SolicitacaoAjuste?.NomeExColaborador ||
                      SolicitacaoAjuste?.EmailExColaborador ||
                      SolicitacaoAjuste?.TelefoneExColaborador) &&
                      <div className="row mb-4 mt-4">
                        <div className={Styles.dadosAjustaveisBox}>
                          <label className={Styles.dadosLabel}>Seus Dados</label>
                          {
                            SolicitacaoAjuste?.NomeExColaborador ?
                              <div className="form-group col-md-6 ">
                                <div className="d-flex justify-content-between">
                                  <label>Nome completo *</label>
                                  <small style={{ color: (!exColaboradorNomeIsValid && (exColaboradorNome.trim().length <= 0 || exColaboradorNome.trim().length > nomeMaxLength)) ? "red" : "inherit" }}>{exColaboradorNome.trim().length} / {nomeMaxLength}</small>
                                </div>
                                <input
                                  className={`form-control ${!exColaboradorNomeIsValid ? Styles.fieldWithError : ""}`}
                                  placeholder="Digite o nome completo"
                                  value={exColaboradorNome}
                                  onChange={(data) => {
                                    setExColaboradorNome(formataNome(data.target.value));
                                    setExColaboradorNomeIsValid(true);
                                  }}
                                  onBlur={() => setExColaboradorNomeIsValid(validarNome(exColaboradorNome, 0, nomeMaxLength))}
                                />
                                {!exColaboradorNomeIsValid ?
                                  <span
                                    className={Styles.errorSpan}
                                  >
                                    Nome inválido
                                    {(exColaboradorNome.trim().split(" ").length <= 1) && ": Nome incompleto"}
                                  </span> : ""
                                }
                              </div> : ""
                          }
                          {
                            SolicitacaoAjuste?.EmailExColaborador ?
                              <div className="form-group col-md-4 ">
                                <label>E-mail *</label>
                                <input
                                  className={`form-control ${!exColaboradorEmailIsValid ? Styles.fieldWithError : ""}`}
                                  placeholder="e-mail@e-mail.com.br"
                                  value={exColaboradorEmail}
                                  onChange={(data) => {
                                    setExColaboradorEmail(data.target.value);
                                    setExColaboradorEmailIsValid(true);
                                  }}
                                  onBlur={() =>
                                    setExColaboradorEmailIsValid(ValidateEmail(exColaboradorEmail))}
                                />
                                {!exColaboradorEmailIsValid ?
                                  <span
                                    className={Styles.errorSpan}
                                  >
                                    E-mail inválido
                                  </span> : ""
                                }
                              </div> : ""
                          }
                          {
                            SolicitacaoAjuste?.TelefoneExColaborador ?
                              <div className="form-group col-md-4 ">
                                <label>Telefone (DDD + Número)</label>
                                <input
                                  className={`form-control ${!exColaboradorTelefoneIsValid ? Styles.fieldWithError : ""}`}
                                  placeholder="(xx) x xxxx-xxxx"
                                  maxLength={15}
                                  value={exColaboradorTelefone}
                                  onChange={(data) => setExColaboradorTelefone(formataTelefone(data.target.value))}
                                  onBlur={() => setExColaboradorTelefoneIsValid(ValidatePhone(exColaboradorTelefone))}
                                />
                                {!exColaboradorTelefoneIsValid ?
                                  <span
                                    className={Styles.errorSpan}
                                  >
                                    Telefone inválido
                                  </span> : ""
                                }
                              </div> : ""
                          }
                        </div>
                      </div>
                    }
                  </>
                  : ""
                }

                {Solicitacao?.TipoSolicitante === 2 ?
                  <>
                    {
                      (SolicitacaoAjuste?.CpfRepresentante ||
                        SolicitacaoAjuste?.NomeRepresentante ||
                        SolicitacaoAjuste?.TelefoneRepresentante ||
                        SolicitacaoAjuste?.EmailRepresentante)
                        ?
                        <div className="row mb-4 mt-4">
                          <div className={Styles.dadosAjustaveisBox}>
                            <label className={Styles.dadosLabel}>Seus Dados</label>
                            {
                              SolicitacaoAjuste?.NomeRepresentante ?
                                <div className="form-group col-md-4 ">
                                  <div className="d-flex justify-content-between">
                                    <label>Nome completo *</label>
                                    <small style={{ color: (!representanteNomeIsValid && (representanteNome.trim().length <= 0 || representanteNome.trim().length > nomeMaxLength)) ? "red" : "inherit" }}>{representanteNome.trim().length} / {nomeMaxLength}</small>
                                  </div>
                                  <input
                                    className={`form-control ${!representanteNomeIsValid ? Styles.fieldWithError : ""}`}
                                    placeholder="Digite o nome completo"
                                    value={representanteNome}
                                    onChange={(data) => {
                                      setRepresentanteNome(formataNome(data.target.value));
                                      setRepresentanteNomeIsValid(true);
                                    }}
                                    onBlur={() => setRepresentanteNomeIsValid(validarNome(representanteNome, 0, nomeMaxLength))}
                                  />
                                  {!representanteNomeIsValid ?
                                    <span
                                      className={Styles.errorSpan}
                                    >
                                      Nome inválido
                                      {(representanteNome.trim().split(" ").length <= 1) && ": Nome incompleto"}
                                    </span> : ""
                                  }
                                </div> : ""
                            }
                            {
                              SolicitacaoAjuste?.CpfRepresentante ?
                                <div className="form-group col-md-4 ">
                                  <label>CPF *</label>
                                  <input
                                    className={`form-control ${(representanteCPFIsValid !== undefined && !representanteCPFIsValid?.isValid) ? Styles.fieldWithError : ""}`}
                                    placeholder="Somente números"
                                    maxLength={14}
                                    value={representanteCPF}
                                    onChange={(data) => setRepresentanteCPF(formataCPF(data.target.value))}
                                    onBlur={CpfOnBlur}
                                  />
                                  {!representanteCPFIsValid?.isValid ?
                                    <span
                                      className={Styles.errorSpan}
                                    >
                                      {representanteCPFIsValid?.error}
                                    </span> : ""
                                  }
                                </div> : ""
                            }
                            {
                              SolicitacaoAjuste?.EmailRepresentante ?
                                <div className="form-group col-md-4 ">
                                  <label>E-mail *</label>
                                  <input
                                    className={`form-control ${!representanteEmailIsValid ? Styles.fieldWithError : ""}`}
                                    placeholder="e-mail@e-mail.com.br"
                                    value={representanteEmail}
                                    onChange={(data) => {
                                      setRepresentanteEmail(data.target.value);
                                      setRepresentanteEmailIsValid(true);
                                    }}
                                    onBlur={() =>
                                      setRepresentanteEmailIsValid(ValidateEmail(representanteEmail))}
                                  />
                                  {!representanteEmailIsValid ?
                                    <span
                                      className={Styles.errorSpan}
                                    >
                                      E-mail inválido
                                    </span> : ""
                                  }
                                </div> : ""
                            }
                            {
                              SolicitacaoAjuste?.TelefoneRepresentante ?
                                <div className="form-group col-md-4">
                                  <label>Telefone (DDD + Número)</label>
                                  <input
                                    className={`form-control ${!representanteTelefoneIsValid ? Styles.fieldWithError : ""}`}
                                    placeholder="(xx) x xxxx-xxxx"
                                    maxLength={15}
                                    value={representanteTelefone}
                                    onChange={(data) => setRepresentanteTelefone(formataTelefone(data.target.value))}
                                    onBlur={() => setRepresentanteTelefoneIsValid(ValidatePhone(representanteTelefone))}
                                  />
                                  {!representanteTelefoneIsValid ?
                                    <span
                                      className={Styles.errorSpan}
                                    >
                                      Telefone inválido
                                    </span> : ""
                                  }
                                </div> : ""
                            }
                          </div>
                        </div> : ""
                    }
                    {
                      (SolicitacaoAjuste?.NomeExColaborador ||
                        SolicitacaoAjuste?.TelefoneExColaborador ||
                        SolicitacaoAjuste?.EmailExColaborador)
                        ?
                        <div className="row mb-4 mt-4">
                          <div className={Styles.dadosAjustaveisBox}>
                            <label className={Styles.dadosLabel}>Dados do Ex-colaborador</label>
                            {
                              SolicitacaoAjuste?.NomeExColaborador ?
                                <div className="form-group col-md-4 ">
                                  <div className="d-flex justify-content-between">
                                    <label>Nome completo *</label>
                                    <small style={{ color: (!exColaboradorNomeIsValid && (exColaboradorNome.trim().length <= 0 || exColaboradorNome.trim().length > nomeMaxLength)) ? "red" : "inherit" }}>{exColaboradorNome.trim().length} / {nomeMaxLength}</small>
                                  </div>
                                  <input
                                    className={`form-control ${!exColaboradorNomeIsValid ? Styles.fieldWithError : ""}`}
                                    placeholder="Digite o nome completo"
                                    value={exColaboradorNome}
                                    onChange={(data) => {
                                      setExColaboradorNome(formataNome(data.target.value));
                                      setExColaboradorNomeIsValid(true);
                                    }}
                                    onBlur={() => setExColaboradorNomeIsValid(validarNome(exColaboradorNome, 0, nomeMaxLength))}
                                  />
                                  {!exColaboradorNomeIsValid ?
                                    <span
                                      className={Styles.errorSpan}
                                    >
                                      Nome inválido
                                      {(exColaboradorNome.trim().split(" ").length <= 1) && ": Nome incompleto"}
                                    </span> : ""
                                  }
                                </div> : ""
                            }
                            {
                              SolicitacaoAjuste?.EmailExColaborador ?
                                <div className="form-group col-md-4 ">
                                  <label>E-mail *</label>
                                  <input
                                    className={`form-control ${!exColaboradorEmailIsValid ? Styles.fieldWithError : ""}`}
                                    placeholder="e-mail@e-mail.com.br"
                                    value={exColaboradorEmail}
                                    onChange={(data) => {
                                      setExColaboradorEmail(data.target.value);
                                      setExColaboradorEmailIsValid(true);
                                    }}
                                    onBlur={() =>
                                      setExColaboradorEmailIsValid(ValidateEmail(exColaboradorEmail))}
                                  />
                                  {!exColaboradorEmailIsValid ?
                                    <span
                                      className={Styles.errorSpan}
                                    >
                                      E-mail inválido
                                    </span> : ""
                                  }
                                </div> : ""
                            }
                            {
                              SolicitacaoAjuste?.TelefoneExColaborador ?
                                <div className="form-group col-md-4 ">
                                  <label>Telefone (DDD + Número)</label>
                                  <input
                                    className={`form-control ${!exColaboradorTelefoneIsValid ? Styles.fieldWithError : ""}`}
                                    placeholder="(xx) x xxxx-xxxx"
                                    maxLength={15}
                                    value={exColaboradorTelefone}
                                    onChange={(data) => setExColaboradorTelefone(formataTelefone(data.target.value))}
                                    onBlur={() => setExColaboradorTelefoneIsValid(ValidatePhone(exColaboradorTelefone))}
                                  />
                                  {(!exColaboradorTelefoneIsValid) ?
                                    <span
                                      className={Styles.errorSpan}
                                    >
                                      Telefone inválido
                                    </span> : ""
                                  }
                                </div> : ""
                            }
                          </div>
                        </div> : ""
                    }
                  </>
                  : ""
                }

                {
                  (SolicitacaoAjuste?.Banco ||
                    SolicitacaoAjuste?.Agencia ||
                    SolicitacaoAjuste?.TipoDeConta ||
                    SolicitacaoAjuste?.NumeroDaConta ||
                    SolicitacaoAjuste?.ChavePix)
                    ?
                    <div className="row mb-4 mt-4">
                      <div className={Styles.dadosAjustaveisBox}>
                        <label className={Styles.dadosLabel}>Dados Bancários</label>
                        {
                          SolicitacaoAjuste?.Banco ?
                            <div className="form-group col-md-4">
                              <div>Banco: </div>
                              <Select
                                value={banco}
                                onChange={(value) => setBanco(value)}
                                options={bancos}
                                isLoading={carregandoBancos}
                                isClearable
                                placeholder={"Selecione um banco"}
                                noOptionsMessage={() => "Nada a selecionar"}
                                className={bancoIsValid ? "" : Styles.fieldWithError}
                                required
                              />
                            </div> : ""
                        }
                        {
                          SolicitacaoAjuste?.TipoDeConta ?
                            <div className="form-group col-md-4">
                              <div>Tipo da Conta:</div>
                              <Select
                                value={tipoConta}
                                onChange={(value) => setTipoConta(value)}
                                options={tiposConta}
                                isClearable
                                placeholder={"Selecione o tipo"}
                                noOptionsMessage={() => "Nada a selecionar"}
                                className={tipoContaIsValid ? "" : Styles.fieldWithError}
                                required
                              />
                            </div> : ""
                        }
                        {
                          SolicitacaoAjuste?.Agencia ?
                            <div className="form-group col-md-4 ">
                              <div className="d-flex gap-2">

                                <div className="flex-grow-1">

                                  <label>Agência</label>
                                  <input className={`form-control requiredValidation ${!agenciaIsValid ? Styles.fieldWithError : ""}`}
                                    value={agencia} placeholder="xxxxx" maxLength={5}
                                    onChange={(data) => {
                                      setAgencia(formataAgencia(data.target.value))
                                      setAgenciaIsValid(true);
                                    }}
                                    required
                                    onBlur={() => setAgenciaIsValid(validaAgencia(agencia))}
                                  />
                                  {!agenciaIsValid ?
                                    <span
                                      className={Styles.errorSpan}
                                    >
                                      Agência inválida
                                    </span> : ""
                                  }
                                </div>
                                <div className={`col-md-3`}>

                                  <label>Dígito</label>
                                  <input className={`form-control`}
                                    value={agenciaDigito} placeholder="" maxLength={1}
                                    onChange={(data) => {
                                      setAgenciaDigito(formataDigito(data.target.value));
                                    }}
                                  />
                                </div>

                              </div>
                            </div> : ""
                        }
                        {
                          SolicitacaoAjuste?.NumeroDaConta ?
                            <div className="form-group col-md-4 ">
                              <div className="d-flex gap-2">
                                <div className='flex-grow-1'>

                                  <label>N.º Conta</label>
                                  <input className={`form-control requiredValidation ${!numeroContaIsValid ? Styles.fieldWithError : ""}`}
                                    value={numeroConta} placeholder="xxxxxxxxxxxx" maxLength={12}
                                    onChange={(data) => {
                                      setNumeroConta(formataNumeroConta(data.target.value));
                                      setNumeroContaIsValid(true);
                                    }}
                                    required
                                    onBlur={() => setNumeroContaIsValid(validaNumeroConta(numeroConta))}
                                  />
                                  {!numeroContaIsValid ?
                                    <span
                                      className={Styles.errorSpan}
                                    >
                                      N.º Conta inválido
                                    </span> : ""
                                  }
                                </div>
                                <div className={`col-md-3`}>

                                  <label>Dígito</label>
                                  <input className={`form-control col-md-2 ${!numeroDigitoIsValid ? Styles.fieldWithError : ""}`}
                                    value={numeroDigito} placeholder="" maxLength={1}
                                    onChange={(data) => {
                                      setNumeroDigito(formataDigito(data.target.value));
                                      setNumeroDigitoIsValid(true);
                                    }}
                                    onBlur={() => setNumeroDigitoIsValid(numeroDigito.length === 1)}
                                  />
                                  {!numeroDigitoIsValid ?
                                    <span
                                      className={Styles.errorSpan}
                                    >
                                      Dígito inválido
                                    </span> : ""
                                  }
                                </div>
                              </div>
                            </div> : ""
                        }
                        {
                          SolicitacaoAjuste?.ChavePix ?
                            <>
                              <div className="form-group col-md-4 ">
                                <label>Tipo PIX</label>
                                <Select
                                  value={tipoChavePix}
                                  onChange={(value) => {
                                    setTipoChavePix(value);
                                    setChavePix("");
                                    setChavePixIsValid(true);
                                  }}
                                  options={tiposChavePix}
                                  isClearable
                                  placeholder={"Selecione o tipo"}
                                  noOptionsMessage={() => "Nada a selecionar"}
                                />
                              </div>
                              <div className="form-group col-md-4">
                                <label>Chave PIX:</label>
                                <input className={`form-control ${!chavePixIsValid ? Styles.fieldWithError : ""}`}
                                  value={chavePix} placeholder="Digite sua chave PIX" disabled={!(tipoChavePix)}
                                  onChange={(data) => {
                                    setChavePix(formataChavePix(data.target.value, tipoChavePix?.value));
                                    setChavePixIsValid(true);
                                  }}
                                  onBlur={() => setChavePixIsValid(validaChavePix(chavePix, tipoChavePix?.value))}
                                />
                                {!chavePixIsValid ?
                                  <span
                                    className={Styles.errorSpan}
                                  >
                                    Chave inválida
                                  </span> : ""
                                }
                              </div>
                            </> : ""
                        }
                      </div>
                    </div> : ""
                }
                {
                  SolicitacaoAjuste?.ReenviarAnexoBancario || SolicitacaoAjuste?.ReenviarAnexoExColaborador ?
                    <div className="row mb-4 mt-4">
                      <div className={Styles.dadosAjustaveisBox}>
                        <div className={Styles.docsText}>
                          <b> Documentos Requeridos:</b>
                          {
                            SolicitacaoAjuste?.ReenviarAnexoBancario ? <span>Documentos bancários.</span> : ""
                          }
                          {
                            SolicitacaoAjuste?.ReenviarAnexoExColaborador ? <span>Documentos do ex-colaborador.</span> : ""
                          }
                        </div>
                        <div {...getRootProps({ className: Styles.dropZone })}>
                          <input {...getInputProps()} />
                          <p>Arraste os arquivos ou clique para adiciona-los</p>
                        </div>
                        <aside>
                          <h4>Arquivos Selecionados</h4>
                          <ul>{preview}</ul>
                        </aside>
                      </div>
                    </div> : ""
                }
              </div>
            </div>
          </main>
          <footer>
            <div className={Styles.buttonsContainer}>
              <button type="button" className="btn btn-primary">Cancelar</button>
              <button
                type="submit"
                onClick={submit}
                className="btn btn-success"
                disabled={!confirmacaoDadosPessoais || !confirmacaoDadosBancarios}
              >
                Enviar Correção
                <FaGreaterThan />
              </button>
            </div>
          </footer>
        </div>
      </form >

      <LoadingModal isLoading={salvandoAjuste} />
    </>
  )
}

export default Ajuste;