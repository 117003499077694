import styled, { css } from 'styled-components';

type ProgressProps = {
  active: boolean;
  current?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex: 1;

  @media only screen and (max-width: 500px) {
    display: none;
  }
`;

export const CurrentChild = styled.div<ProgressProps>`
  display: flex;
  flex: 1;
  align-items: center;
  white-space: nowrap;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:last-child {
    flex: 0;
  }

  &:not(:last-child):after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${props => (props.active ? '#fff' : '#6c757d')};
  }
  p {
    margin: 0 0.5rem;
    padding: 0;
    font-weight: 500;
    color: ${props => (props.active ? '#fff' : '#6c757d')};
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  transform: translate(0px, -15px);
`;

const background = css`background-color: #316696;`;

export const IconContent = styled.div<ProgressProps>`
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: ${props => (props.active ? '#215696' : '#6c757d')};
  cursor: ${props => props.active && 'pointer'};

  ${props => props.current && background };

  svg {
    font-size: 1rem;
    color: #f8f8f8;
  }
`;
