import Representante from './RepresentanteForm';
import Anexo from './AnexosForm';
import ContaBancaria from './ContaBancariaForm';
import Confirmacao from './Confirmacao';
import Opcao from './Opcao';
import ExColaborador from '../SolicitacaoReutilizavel/ExColaboradorForm';
import { FormStepper } from '../../components/FormWithSteps/FormStepper';
import { FormStep } from '../../components/FormWithSteps/FormStep';
import Styles from "../SolicitacaoReutilizavel/styles.module.scss";
import { useEffect, useState } from 'react';
import { usePagesContext } from '../../contexts/PagesPPR';
import { IDadosBancarios, IDadosIdentificacao, IDadosRepresentante } from '../../interfaces/IPPRInativoSolicitacao';
import { EnumTipoSolicitacao } from '../../enums/EnumTipoSolicitacao';
import { geraNotificacao } from "../../utils/funcoesNotificacao";
import api from "../../services/api";

const labelsExColaborador = [
    'Identificação',
    'Dados do Ex-Colaborador',
    'Conta Bancária',
    'Confirmação',
];

const labelsRepresentanteLegal = [
    'Identificação',
    'Ex-Colaborador',
    'Representante',
    'Anexos',
    'Conta Bancária',
    'Confirmação',
]

export const Formulario: React.FC = () => {
    const PagesContext = usePagesContext();
    const tipoSolicitante = PagesContext.tipoSolicitante;

    const [formularioIdentificacao, setFormularioIdentificacao] = useState<IDadosIdentificacao | null>(null);
    const  [formularioRepresentante, setFormularioRepresentante] = useState<IDadosRepresentante | null>(null);
    const [formularioBanco, setFormularioBanco] = useState<IDadosBancarios | null>(null);
    const [anexos, setAnexos] = useState<any[]>([]); 
    
    useEffect(() => {
        if (PagesContext.anoBase <= 0)
            fetchConfiguracao();        
    }, [])

    function PreencheIdentificacao(dados: IDadosIdentificacao) {
        setFormularioIdentificacao(dados);
    }

    function PreencheRepresentante(dados: IDadosRepresentante) {
        setFormularioRepresentante(dados);
    }

    function PreencherDadosBancarios(dados: IDadosBancarios) {
        setFormularioBanco(dados);
    }

		function fetchConfiguracao() {
      api.get("/api/ConfiguracoesPPRInativo/AnoBase")
        .then((res) => {
          const anoBase = res?.data?.AnoBase;
          if (anoBase && anoBase > 2021) {
            PagesContext.DefinaAnoBaseConfiguracao(res?.data?.AnoBase);
          } else {
            geraNotificacao({
              tipo: "customizado",
              mensagem:
                "O prazo para solicitação do PPR inativo está encerrado.\nPara maior esclarecimento entrar em contato com o RH da sua unidade.",
            })
          }
        })
        .catch((err) => {
          geraNotificacao({
            tipo: "customizado",
            mensagem: JSON.stringify(
              err?.data ?? "Falha de comunicação com o servidor"
            ),
            severity: "error",
          });
        });
    }

    return (
        <div className={`${Styles.formulario}`}>
            <FormStepper labels={tipoSolicitante === 1 ? labelsExColaborador : labelsRepresentanteLegal}>
                <FormStep>
                    <Opcao tipoSolicitante={tipoSolicitante} setTipoSolicitante={PagesContext.changeTipoSolicitante} />
                </FormStep>
                <FormStep>
                    <ExColaborador formulario={formularioIdentificacao} preencheFormulario={PreencheIdentificacao} tipoSolicitacao={EnumTipoSolicitacao.PPR_INATIVO}/>
                </FormStep>
                {tipoSolicitante === 2 &&
                    <FormStep>
                        <Representante cpfExcolaborador={formularioIdentificacao?.Cpf ?? ""} formulario={formularioRepresentante} preencheFormulario={PreencheRepresentante} />
                    </FormStep>
                }
                {tipoSolicitante === 2 &&
                    <FormStep>
                        <Anexo anexosOriginais={anexos} preencheAnexos={setAnexos} />
                    </FormStep>
                }
                <FormStep>
                    <ContaBancaria tipoSolicitante={tipoSolicitante} formulario={formularioBanco} preencheFormulario={PreencherDadosBancarios} />
                </FormStep>
                <FormStep>
                    <Confirmacao formulario={{ 
                        ...(formularioIdentificacao ?? {} as IDadosIdentificacao), 
                        ...(formularioRepresentante ?? {} as IDadosRepresentante), 
                        ...(formularioBanco ?? {} as IDadosBancarios) 
                    }} anexos={anexos} />
                </FormStep>
            </FormStepper>
        </div>
    );
};
