import CryptoJS from "crypto-js";

export function AESEncryptMessage(message: string) {
    const security_key = process.env.REACT_APP_SECURITY_KEY;
    
    if (!security_key) return;

    var key = CryptoJS.enc.Utf8.parse(security_key);
    var iv = CryptoJS.enc.Utf8.parse(security_key);

    var parsedMessage = CryptoJS.enc.Utf8.parse(message)

    var options = {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }

    var encrypted = CryptoJS.AES.encrypt(parsedMessage, key, options);

    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);


}




