import { ITipoChavePix } from "../interfaces/IPPRInativoSolicitacao";

export function formataCPF(cpf: string) {
    return cpf
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
}

export function formataTelefone(telefone: string) {
    return telefone
        .replace(/\D/g, '')
        .replace(/([0-9]{2})(\d)/, '($1) $2')
        .replace(/([0-9]{5})(\d)/, '$1-$2')
        .replace(/(-[0-9]{4})\d+?$/, '$1');

}

export function formataAgencia(agencia: string) {
    return agencia
        .replace(/\D/g, '')
        .replace(/(\d{5})\d+?$/, '$1')
        .toUpperCase();;
}

export function formataNumeroConta(numeroConta: string) {
    return numeroConta
        .replace(/\D/g, '')
        .replace(/(\d{12})\d+?$/, '$1')
        .toUpperCase();
}

export function formataOperacao(operacao: string) {
    return operacao
        .replace(/\D/g, '')
        .replace(/(\d{3})\d+?$/, '$1');
}

export function formataSolicitacao(operacao: string) {
    return operacao
        .replace(/\D/g, '')
        .replace(/(\d{10})\d+?$/, '$1');
}

export function formataNome(nome: string) {
    return nome
        .replace(/[^a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ]+$/u, '')
        .replace(/( {2})+/, ' ')
        .replace(/^[ ]/, '');
}

export function formataChavePix(chavePix: string, tipoChave: ITipoChavePix) {
    switch (tipoChave) {
        case 1: return formataCPF(chavePix)
        case 2: return formataTelefone(chavePix);
        default: return chavePix
    }
}

export function formataDigito(digito: string) {
    return digito
        .replace(/[^xX0-9]+/, '')
        .replace("X", "x")
        .replace(/(\d{1})\d+?$/, '$1')
        .toUpperCase();
}