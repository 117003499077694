import React, { useEffect, useState } from "react";
import { FaLongArrowAltRight, FaPlus, FaCheck } from "react-icons/fa";
import { PagesPPR, usePagesContext } from "../../contexts/PagesPPR";
import Styles from "./styles.module.scss";
import { useForm } from "../../hooks/form";
import api from "../../services/api";
import { geraNotificacao } from "../../utils/funcoesNotificacao";

function TelaInicial() {
  const PagesContext = usePagesContext();
  const { setCurrentStep } = useForm();

  const [ehColaborador, setEhColaborador] = useState(true);

  function fetchConfiguracao() {
    return api
      .get("/api/ConfiguracoesPPRInativo/AnoBase")
      .then((res) => {
        const anoBase = res?.data?.AnoBase;
        if (anoBase && anoBase > 2021) {
          PagesContext.DefinaAnoBaseConfiguracao(res?.data?.AnoBase);
          PagesContext.changePage(PagesPPR.NovaSolicitacao);
          window.scrollTo(0, 0);
        } else {
          geraNotificacao({
            tipo: "customizado",
            mensagem:
              "O prazo para solicitação do PPR inativo está encerrado.\nPara maior esclarecimento entrar em contato com o RH da sua unidade.",
          })
        }
      })
      .catch((err) => {
        geraNotificacao({
          tipo: "customizado",
          mensagem: JSON.stringify(
            err?.data ?? "Falha de comunicação com o servidor"
          ),
          severity: "error",
        });
      });
  }

  useEffect(() => {
    PagesContext.AtualizarProtocolo(null);
    setCurrentStep(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    
    if (window.location.pathname.toString() != "/")
    {
      window.location.pathname = "/";
    }
  }, []);

  return (
    <div className={`row ${Styles.main}`}>
      <div className={Styles.titlesContainer}>
        <div className={`${Styles.title}`}>
          <h2>Bem-vindo ao Portal do Colaborador Havan</h2>
        </div>
      </div>
      <div className={`${Styles.telaInicial}`}>
        <div className={Styles.cardsContainer}>
          <div className={Styles.card} onClick={() => setEhColaborador(true)}>
            <span className={Styles.separator}></span>
            <p style={{ color: ehColaborador ? "green" : "#06357a" }}>
              Sou Colaborador
            </p>
            {ehColaborador ? <FaCheck /> : ""}
          </div>
          <div className={Styles.card} onClick={() => setEhColaborador(false)}>
            <span className={Styles.separator}></span>
            <p style={{ color: !ehColaborador ? "green" : "#06357a" }}>
              Sou Ex-Colaborador
            </p>
            {!ehColaborador ? <FaCheck /> : ""}
          </div>
        </div>
        <div className={Styles.buttons}>
          {ehColaborador ? (
            <div>
              <button
                className="btn btn-primary form-control"
                onClick={() => {
                  window.location.href =
                    "https://platform.senior.com.br/login/?redirectTo=https://platform.senior.com.br/senior-x/&tenant=havan.com.br";
                }}
              >
                <span>Continuar</span>
              </button>
            </div>
          ) : (
            <>
              <div>
                <button
                  className="btn btn-primary form-control"
                  onClick={fetchConfiguracao}
                >
                  <FaPlus />
                  <span>Solicitação PPR - Ex-colaborador</span>
                </button>
              </div>
              <div>
                <button
                  className="btn btn-success form-control"
                  onClick={() => {
                    PagesContext.changePage(PagesPPR.AcompanharSolicitacao);
                    window.scrollTo(0, 0);
                  }}
                >
                  <FaLongArrowAltRight />
                  <span>Acompanhar solicitação PPR - Ex-colaborador</span>
                </button>
              </div>
              <div>
                <button
                  className={`btn ${Styles.btnInformes} form-control`}
                  onClick={() => {
                    PagesContext.changePage(PagesPPR.InformesRendimento);
                    window.scrollTo(0, 0);
                  }}
                >
                  <span>Informe de Rendimentos</span>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TelaInicial;
