import {
  PageBody,
} from "@havan/fit-components";
// eslint-disable-next-line

import { Body } from "./GlobalStyles";
import Main from "./components/MainComponent/Main";
import Notificacao from "./components/Notificacao";

function App() {


  return (
    <PageBody>
      <Body >
        <Main />
        <Notificacao/>
      </Body>
    </PageBody>
  );
}

export default App;
