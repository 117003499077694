/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { FaLessThan } from 'react-icons/fa';
import EtapasModal from '../../components/EtapasModal';
import { PagesPPR, usePagesContext } from '../../contexts/PagesPPR';
import api from '../../services/api';
import { geraNotificacao } from '../../utils/funcoesNotificacao';
import Styles from "./styles.module.scss";


function Acompanhamento() {
    const PagesContext = usePagesContext();
    const [openModal, setOpenModal] = useState(false);

    async function acompanhaSolicitacao(){
        if(!PagesContext.protocolo || PagesContext.protocolo <= 0){
            geraNotificacao({
                tipo: "customizado",
                mensagem: "Informe o protocolo.",
                severity: "error"
            }); 
            return;
        }

        if(String(PagesContext.protocolo).length < 9 ){
            geraNotificacao({
                tipo: "customizado",
                mensagem: "O protocolo deve ter 9 dígitos.",
                severity: "error"
            }); 
            return;
        }

        let existeSolicitacao = false;

        await api.get(`/api/Solicitacao/ExisteSolicitacao?protocolo=${PagesContext.protocolo}`)
        .then((res:any) => {
            existeSolicitacao = res.data;
            if(!existeSolicitacao) {
                geraNotificacao({
                    tipo: "customizado",
                    mensagem: "Não existe solicitação em andamento com o protocolo informado.",
                    severity: "error"
                });
            } else 
                setOpenModal(true);            
        })
        .catch((erro: any) => {
            geraNotificacao({
                tipo: "customizado",
                mensagem: erro?.response?.data ?? "Houve uma falha ao tentar validar protocolo de solicitação",
                severity: "error"
            });            
         });
    }

    function handleProtocoloChange(protocolo: string){
        if(protocolo.length <= 9){
            if(Number(protocolo) === 0)
                PagesContext.AtualizarProtocolo(null);
            else
                PagesContext.AtualizarProtocolo(Number(protocolo));
        }
    }

    useEffect(() => {
        if(PagesContext.protocolo){
            acompanhaSolicitacao();
        }
    }, [])

    return (
        <div className={Styles.container}>
            <div className={`row ${Styles.customBox}`}>
                <header>
                    <button 
                        className={`btn ${Styles.botaoVoltar}`} 
                        onClick={() => PagesContext.changePage(PagesPPR.Inicio)}
                    >
                        <FaLessThan />
                    </button>
                </header>
                <main className={Styles.mainContainer}>
                    <h1>Digite o seu Protocolo</h1>
                    <input 
                        type="number" 
                        placeholder="Somente números" 
                        className="form-control"
                        value={String(PagesContext.protocolo)}
                        onChange={(event) => handleProtocoloChange((event.target.value))}
                    />
                    <p>
                        Enviamos o número do seu protocolo para o e-mail.
                    </p>
                </main>
                <footer className={Styles.footerContainer}>
                    <button onClick={acompanhaSolicitacao}>Acompanhar Solicitação</button>
                </footer>

                <EtapasModal 
                    open={openModal} 
                    onClose={() => 
                    setOpenModal(false)} 
                    closable={true}
                    protocolo={PagesContext.protocolo ? PagesContext.protocolo : 0}
                />
            </div>

            <a 
                className={Styles.resendProtocol} 
                href="#"
                onClick={() => PagesContext.changePage(PagesPPR.ReenvioDeProtocolo)}
                >Reenviar número de protocolo</a>
        </div>
    )
}

export default Acompanhamento
