import React from "react";

import { NotificationProvider } from "../contexts/Notifications";
import { PagesPPRProvider } from "../contexts/PagesPPR";
import { FormProvider } from './form';

const Hooks: React.FC = ({ children }) => (
  <PagesPPRProvider>
    <NotificationProvider>
      <FormProvider>
        {children}
      </FormProvider>
    </NotificationProvider>
  </PagesPPRProvider>
);

export default Hooks;
