import React, { useCallback, useState } from 'react';
import Styles from "../SolicitacaoReutilizavel/styles.module.scss";
import { FaGreaterThan, FaLessThan } from 'react-icons/fa';
import { useForm } from '../../hooks/form';
import { PagesPPR, usePagesContext } from '../../contexts/PagesPPR';
import CustomModal from '../../components/CustomModal';
import { ITipoSolicitante } from '../../interfaces/IPPRInativoSolicitacao';

interface Props {
    tipoSolicitante: ITipoSolicitante;
    setTipoSolicitante: (value: ITipoSolicitante) => void;
}

const Opcao: React.FC<Props> = () => {

    const PagesContext = usePagesContext();
    const tipoSolicitante = PagesContext.tipoSolicitante;
    const funcionarioLogado = PagesContext.funcionarioLogado;

    const { setCurrentStep, step } = useForm();

    const [openModal, setOpenModal] = useState(false);

    const VoltarForm = () => {
        PagesContext.changeTipoSolicitante(1);
        PagesContext.setFuncionarioLogado(false);
        PagesContext.changePage(PagesPPR.Inicio);
    };

    const ContinueClick = useCallback(
        () => {
            if (tipoSolicitante === 1 || funcionarioLogado) {
                setCurrentStep(step + 1);
            }
            if (tipoSolicitante === 2) {
                setOpenModal(true);
            }
        },
        [tipoSolicitante]
    )

    function Login() {
        const webUrl = process.env.REACT_APP_WEB;
        window.location.href = `https://sistema.havan.com.br/AutenticacaoApi/Entrar?ReturnUrl=${webUrl}`;
    }

    function handleTipoChange(tipo: ITipoSolicitante) {
        PagesContext.changeTipoSolicitante(tipo);
    }

    const desabilitaBotao = !(tipoSolicitante)

    return (
        <>
            <div className={`row ${Styles.customBox}`} >
                <div className={`${Styles.botaoVoltarRow}`}>
                    <button className={`btn ${Styles.botaoVoltar}`} onClick={VoltarForm}><FaLessThan /></button>
                </div>
                <div className={`col-md-12 ${Styles.customForm} mb-4`}>
                    <h2>Identificação</h2>
                    <div className='mt-4 mb-4'>
                        <p>Caso seja um <strong>Ex-colaborador</strong>, selecione a opção "Ex-colaborador" e siga com o preenchimento de seus dados.</p>
                        <p>Caso seja um <strong>Representante Legal</strong>, procure a filial Havan mais próxima.</p>
                    </div>
                    <div className='mt-4 mb-4'>
                        <p>Seguir cadastro como:</p>
                    </div>
                    <div className={`d-flex ${Styles.inicialButtonsBox}`}>
                        <div>
                            <input type="radio" name="tipoSolicitante" id="exColaborador"
                                checked={tipoSolicitante === 1}
                                onChange={() => handleTipoChange(1)}
                            />
                            <label htmlFor="exColaborador">Ex-colaborador</label>

                        </div>
                        <div style={{minWidth: "180px"}}>
                            <input type="radio" name="tipoSolicitante" id="representante"
                                checked={tipoSolicitante === 2}
                                onChange={() => handleTipoChange(2)}
                            />
                            <label htmlFor="representante">Representante Legal</label>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.botaoContinuarRow}`}>
                    <button
                        className={`btn ${Styles.botaoContinuar}`}
                        onClick={ContinueClick}
                        disabled={desabilitaBotao}
                    >
                        Continuar Solicitação
                        <FaGreaterThan />
                    </button>
                </div>
            </div>
            <CustomModal open={openModal} onClose={() => setOpenModal(false)}
                closable
            >
                <h3>Solicitação de PPR Inativo como Representante Legal</h3>
                <div className='mt-4 mb-4' style={{ textAlign: "center" }}>
                    <button
                        className={`btn btn-primary`}
                        onClick={Login}
                    >
                        Login
                    </button>
                </div>

            </CustomModal>
        </>
    )
}

export default Opcao
