import { ConfirmacaoContato, ITipoMensagem, IOrigemSms } from "../interfaces/IPPRInativoSolicitacao";
import api from "../services/api";
import { geraNotificacao } from "./funcoesNotificacao";

function envioPorTelefone(tipoConfirmacao: ConfirmacaoContato) {
    return tipoConfirmacao === "T";
}

export function enviaCodigo(tipoConfirmacao: ConfirmacaoContato, telefone: string, email: string, nome: string, cpf: string, origem: IOrigemSms = 1, tipoMensagem: ITipoMensagem = 1) {
    api.get(`api/Solicitacao/AskToken?EnviarViaSms=${envioPorTelefone(tipoConfirmacao)}&EnviarPara=${envioPorTelefone(tipoConfirmacao) ? telefone : email}&Nome=${nome}&cpf=${cpf}&OrigemSms=${origem}&tipoEmail=${tipoMensagem}`)
}

export async function confirmaCodigo(codigo: string, cpf: string, avanca: () => void) {
    return api.get(`api/Solicitacao/GetToken?cpf=${cpf}&token=${codigo}`)
        .then((res: any) => {
            if (res.data === true) {
                avanca();
            }
            else {
                geraNotificacao({
                    tipo: "customizado",
                    mensagem: "Código incorreto.",
                    severity: "error"
                });
            }
        })
        .catch((err => geraNotificacao({
            tipo: "customizado",
            mensagem: `Erro ao confirmar código: ${JSON.stringify(err)}`,
            severity: "error"
        })))
}