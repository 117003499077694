import React, { useCallback, useEffect, useState } from 'react'
import { FaGreaterThan, FaLessThan } from 'react-icons/fa';
import { useForm } from '../../hooks/form';
import Styles from "../SolicitacaoReutilizavel/styles.module.scss";
import { formataCPF, formataNome, formataTelefone } from '../../utils/formataCampos';
import { validarNome, ValidateEmail, ValidatePhone } from '../../utils/validaCampos';
import { ValidateCPF } from '@havan/fit-components';
import { geraNotificacao } from '../../utils/funcoesNotificacao';
import CodigoVerificacao from "../../components/CodigoVerificacao";
import { confirmaCodigo, enviaCodigo } from '../../utils/envioCodigo';
import { ConfirmacaoContato, IDadosRepresentante } from '../../interfaces/IPPRInativoSolicitacao';

interface DadosIniciaisProps {
    cpfExcolaborador: string;
    formulario: IDadosRepresentante | null;
    preencheFormulario: (dados: IDadosRepresentante) => void;
}

interface IsValidCpf {
    isValid: boolean;
    error?: string;
}

const RepresentanteForm: React.FC<DadosIniciaisProps> = ({ cpfExcolaborador, formulario, preencheFormulario }) => {

    const [cpfRepresentante, setCpfRepresentante] = useState("");
    const [nomeRepresentante, setNomeRepresentante] = useState("");
    const [emailRepresentante, setEmailRepresentante] = useState("");
    const [telefoneRepresentante, setTelefoneRepresentante] = useState("");
    const [tipoConfirmacao, setTipoConfirmacao] = useState<ConfirmacaoContato>("E");
    const [conjugueCompanheiro, setConjugueCompanheiro] = useState(false);
    const [herdeiroDescendente, setHerdeiroDescendente] = useState(false);
    const [semRelacao, setSemRelacao] = useState(false);
    const validacaoCheckBoxes = (conjugueCompanheiro || herdeiroDescendente || semRelacao);
    const nomeMaxLength = 100;

    const [nomeIsValid, setNomeIsValid] = useState<boolean>(true);
    const [cpfIsValid, setCpfIsValid] = useState<IsValidCpf>();
    const [emailIsValid, setEmailIsValid] = useState<boolean>(true);
    const [telefoneIsValid, setTelefoneIsValid] = useState<boolean>(true);

    const [openModal, setOpenModal] = useState(false)

    const { setCurrentStep, step } = useForm();

    const formaContato = () : string => {
        if (tipoConfirmacao === "E")
            return "email";

        if (tipoConfirmacao === "T")
            return "telefone";
        
        return "";
    }

    const contatoVerificacao = () : string => {
        if (tipoConfirmacao === "E")
            return emailRepresentante;

        if ( tipoConfirmacao === "T" )
            return telefoneRepresentante;

        return "";
    }

    const confirmacao = (
        (!!cpfRepresentante && cpfIsValid?.isValid) &&
        (!!nomeRepresentante && nomeIsValid) &&
        (!!emailRepresentante && emailIsValid) &&
        (!!telefoneRepresentante && telefoneIsValid) &&
        (!!validacaoCheckBoxes)
    );

    const avancaForm = useCallback(
        () => {
            if (confirmacao) {
                preencheFormulario({
                    CpfRepresentante: cpfRepresentante, 
                    NomeRepresentante: nomeRepresentante, 
                    EmailRepresentante: emailRepresentante, 
                    TelefoneRepresentante: telefoneRepresentante, 
                    ConjugueCompanheiro: conjugueCompanheiro, 
                    HerdeiroDescendente: herdeiroDescendente, 
                    SemRelacao: semRelacao, 
                    TipoConfirmacao: tipoConfirmacao
                })
                setCurrentStep(step + 1);
            }
        },
        [step, setCurrentStep, preencheFormulario, cpfRepresentante, nomeRepresentante, emailRepresentante, telefoneRepresentante, conjugueCompanheiro, herdeiroDescendente, semRelacao, confirmacao, tipoConfirmacao],
    )

    const VoltarForm = useCallback(
        () => {
            setCurrentStep(step - 1);
        },
        [step, setCurrentStep],
    )

    function validarCampos() {
        let erros = [];
        const isValid = validarCPF(cpfRepresentante);
        if (!isValid.isValid) {
            erros.push(isValid.error ? isValid.error : "");
        }

        if (!emailIsValid) {
            erros.push("E-mail inválido");
        }

        if (!telefoneIsValid) {
            erros.push("Telefone inválido");
        }

        if (!nomeIsValid) {
            erros.push("Nome inválido");
        }

        if (erros.length > 0) {
            geraNotificacao({
                tipo: "customizado",
                mensagem: erros.join(", "),
                severity: "error"
            });
            return false;
        }

        return true;
    }

    const reenviaCodigo = () => {
        enviaCodigo(tipoConfirmacao, telefoneRepresentante, emailRepresentante, nomeRepresentante, cpfRepresentante);
    }

    const submit = (codigo: string) => {
        return confirmaCodigo(codigo, cpfRepresentante, avancaForm)
    }

    function ContinueClick() {
        if (!validarCampos()) return;
        enviaCodigo(tipoConfirmacao, telefoneRepresentante, emailRepresentante, nomeRepresentante, cpfRepresentante);
        setOpenModal(true);
    }

    useEffect(() => {
        setCpfIsValid({ isValid: true });

    }, []);

    useEffect(() => {
        if (formulario) {
            setCpfRepresentante(formulario.CpfRepresentante);
            setNomeRepresentante(formulario.NomeRepresentante);
            setEmailRepresentante(formulario.EmailRepresentante)
            setTelefoneRepresentante(formulario.TelefoneRepresentante);
            setTipoConfirmacao(formulario.TipoConfirmacao);
            setConjugueCompanheiro(formulario.ConjugueCompanheiro);
            setHerdeiroDescendente(formulario.HerdeiroDescendente);
            setSemRelacao(formulario.SemRelacao);
        }
    }, [formulario])

    function handleCheckBoxesOnChange(checkBox: 1 | 2 | 3) {
        let conjugue = false;
        let herdeiro = false;
        let relacao = false;
        switch (checkBox) {
            case 1: conjugue = !conjugueCompanheiro; break;
            case 2: herdeiro = !herdeiroDescendente; break;
            case 3: relacao = !semRelacao; break;
        }
        setConjugueCompanheiro(conjugue);
        setHerdeiroDescendente(herdeiro);
        setSemRelacao(relacao);
    }

    function validarCPF(cpf: string) {
        let isValid: IsValidCpf = ValidateCPF(cpf);

        if (cpf === cpfExcolaborador) {
            isValid.isValid = false;
            isValid.error = "CPF igual ao CPF do Ex-colaborador."
        }

        return isValid;
    }

    return (
        <>
            <div className={`row ${Styles.customBox}`} >
                <div className={`${Styles.botaoVoltarRow}`}>
                    <button className={`btn ${Styles.botaoVoltar}`} onClick={VoltarForm}><FaLessThan /></button>
                </div>
                <div className={`col-md-12 ${Styles.customForm}`}>
                    <h2>Dados do Representante Legal</h2>
                    <p>Todos os campos são obrigatórios</p>
                    <div className="row mb-4">
                        <div className="form-group col-md-4 ">
                            <label>CPF *</label>
                            <input
                                className={`form-control ${(cpfIsValid !== undefined && !cpfIsValid?.isValid) ? Styles.fieldWithError : ""}`}
                                placeholder="Somente números"
                                maxLength={14}
                                value={cpfRepresentante}
                                onChange={(data) => setCpfRepresentante(formataCPF(data.target.value))}
                                onBlur={() => setCpfIsValid(validarCPF(cpfRepresentante))}
                            />
                            {!cpfIsValid?.isValid ?
                                <span
                                    className={Styles.errorSpan}
                                >
                                    {cpfIsValid?.error}
                                </span> : ""
                            }
                        </div>
                        <div className="form-group col-md-8 ">
                            <div className="d-flex justify-content-between">
                                <label>Nome completo *</label>
                                <small style={{ color: (!nomeIsValid && (nomeRepresentante.trim().length <= 0 || nomeRepresentante.trim().length > nomeMaxLength)) ? "red" : "inherit" }}>{nomeRepresentante.trim().length} / {nomeMaxLength}</small>
                            </div>
                            <input
                                className={`form-control ${!nomeIsValid ? Styles.fieldWithError : ""}`}
                                placeholder="Digite aqui o nome completo do representante"
                                value={nomeRepresentante}
                                onChange={(data) => {
                                    setNomeRepresentante(formataNome(data.target.value));
                                    setNomeIsValid(true);
                                }}
                                onBlur={() => setNomeIsValid(validarNome(nomeRepresentante, 0, nomeMaxLength))}
                            />
                            {!nomeIsValid ?
                                <span
                                    className={Styles.errorSpan}
                                >
                                    Nome inválido
                                    {(nomeRepresentante.trim().split(" ").length <= 1) && ": Nome incompleto"}
                                </span> : ""
                            }
                        </div>

                    </div>
                    <div className="row mb-4">
                        <div className="form-group col-md-6 ">
                            <label>E-mail *</label>
                            <input
                                className={`form-control ${!emailIsValid ? Styles.fieldWithError : ""}`}
                                placeholder="e-mail@e-mail.com.br"
                                value={emailRepresentante}
                                onChange={(data) => {
                                    setEmailRepresentante(data.target.value);
                                    setEmailIsValid(true);
                                }}
                                onBlur={() => setEmailIsValid(ValidateEmail(emailRepresentante))}
                            />
                            {!emailIsValid ?
                                <span
                                    className={Styles.errorSpan}
                                >
                                    E-mail inválido
                                </span> : ""
                            }
                        </div>
                        <div className="form-group col-md-6 ">
                            <label>Celular (DDD + Número) *</label>
                            <input
                                className={`form-control ${!telefoneIsValid ? Styles.fieldWithError : ""}`}
                                placeholder="(xx) x xxxx-xxxx"
                                maxLength={15}
                                value={telefoneRepresentante}
                                onChange={(data) => {
                                    setTelefoneRepresentante(formataTelefone(data.target.value));
                                    setTelefoneIsValid(true);
                                }}
                                onBlur={() => setTelefoneIsValid(ValidatePhone(telefoneRepresentante))}
                            />
                            {!telefoneIsValid ?
                                <span
                                    className={Styles.errorSpan}
                                >
                                    Telefone inválido
                                </span> : ""
                            }
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="form-group col-md-12">
                            <label>Receber código de confirmação *:</label>
                            <br />
                            <div className="form-check form-check-inline">
                                <input type="radio" name="confirmacao" id="email"
                                    checked={tipoConfirmacao === "E"} onChange={() => setTipoConfirmacao("E")}
                                />
                                <label htmlFor="email">&nbsp;E-mail</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input type="radio" name="confirmacao" id="telefone"
                                    checked={tipoConfirmacao === 'T'} onChange={() => setTipoConfirmacao("T")}
                                />
                                <label htmlFor="telefone">&nbsp;Telefone</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="form-group col-md-12">
                            <label>Relação com ex-colaborador * :</label>
                            <br />
                            <div className="form-check ">
                                <input type="checkbox" id="conjugueCompanheiro"
                                    checked={conjugueCompanheiro} onChange={() => handleCheckBoxesOnChange(1)}
                                />
                                <label htmlFor="conjugueCompanheiro">&nbsp;Cônjugue ou companheiro (a)</label>
                            </div>
                            <div className="form-check ">
                                <input type="checkbox" id="herdeiroDescendente"
                                    checked={herdeiroDescendente} onChange={() => handleCheckBoxesOnChange(2)}
                                />
                                <label htmlFor="herdeiroDescendente">&nbsp;Herdeiro ou Descendente</label>
                            </div>
                            <div className="form-check ">
                                <input type="checkbox" id="semRelacao"
                                    checked={semRelacao} onChange={() => handleCheckBoxesOnChange(3)}
                                />
                                <label htmlFor="semRelacao">&nbsp;Não tenho relação com o ex-colaborador</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.botaoContinuarRow}`}>
                    <button
                        className={`btn ${Styles.botaoContinuar}`}
                        onClick={ContinueClick}
                        disabled={!confirmacao}
                    >
                        Continuar Solicitação
                        <FaGreaterThan />
                    </button>
                </div>
            </div>
            <CodigoVerificacao
                open={openModal}
                closeModal={() => setOpenModal(false)}
                reenviarCodigo={reenviaCodigo}
                submit={submit}
                contato={contatoVerificacao()}
                formaContato={formaContato()}
            />
        </>
    )
}

export default RepresentanteForm