import React, { useCallback, useEffect, useState } from 'react'
import { FaGreaterThan, FaLessThan } from 'react-icons/fa';
import { useForm } from '../../hooks/form';
import Styles from "../SolicitacaoReutilizavel/styles.module.scss";
import { geraNotificacao } from '../../utils/funcoesNotificacao';
import _ from "lodash";
import { useDropzone } from 'react-dropzone';
import DocumentImg from '../../assets/document.svg'

interface DadosIniciaisProps {
    anexosOriginais: any[]
    preencheAnexos: (value: any[]) => void;
}


const AnexosForm: React.FC<DadosIniciaisProps> = ({ anexosOriginais, preencheAnexos }) => {

    const [anexos, setAnexos] = useState<any[]>([]);

    const { setCurrentStep, step } = useForm();

    const maxNameLength = 150;

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles: any) => {
            let arquivos_iguais: string[] = [];
            let arquivos_para_inserir: any[] = [];

            acceptedFiles.forEach((newFile: any) => {// Para cada arquivo enviado
                let jainserido = false;
                anexos.forEach((anexo: any) => {// Para cada arquivo já anexo
                    jainserido = anexo.path === newFile.path;// Verifica se é o mesmo e guarda o valor em variavel
                });
                // O valor é guardado em variavel para poder verificar fora do loop de "anexos.forEach",
                // no caso de "anexos" não conter nenhum arquivo ainda.
                if (jainserido) {
                    if (!arquivos_iguais.includes(newFile.path)) {
                        arquivos_iguais.push(newFile.path)
                    }
                }
                else {
                    arquivos_para_inserir.push(newFile)
                }
            });

            if (arquivos_iguais.length > 0) {
                geraNotificacao({ tipo: "customizado", mensagem: `Os seguintes anexos já foram inseridos:\n\t${arquivos_iguais.join("\n\t")}` });
            }

            setAnexos([...anexos, ...arquivos_para_inserir.map((file: any) => {

                if (file.type.includes("text/"))
                    return Object.assign(file, {
                        preview: DocumentImg
                    });

                return Object.assign(file, {
                    preview: URL.createObjectURL(file)
                });
            })]);
        }
    });

    const preview = anexos.map((file: any, fileIdx: number) => (
        <div className={Styles.thumb} key={file.name}>
            <div className={Styles.thumbInner}>
                <img
                    src={file.preview}
                    title={file.path}
                    alt={file.path}
                    className={Styles.img}
                />
                <p title={file.path} className={Styles.fileName}>{file.path}</p>
                <span
                    className={Styles.removeFileText}
                    onClick={() => removeArquivo(fileIdx)}
                >
                    X
                </span>
            </div>
        </div>
    ));

    const ValidaAnexos = useCallback(
        () => {
            let anexo_nome_maior: string[] = [];
            anexos.forEach(anexo => {
                if (anexo.name.length > maxNameLength)
                    anexo_nome_maior.push(anexo.name);
            })
            if (anexo_nome_maior.length > 0) {
                geraNotificacao({ tipo: "customizado", mensagem: `Os seguintes anexos possuem o nome maior do que o aceitável:\n\t${anexo_nome_maior.join("\n\t")}` });
                return false;
            }
            return true;
        }, [anexos])

    const avancaForm = useCallback(
        () => {
            if (anexos.length) {
                preencheAnexos(anexos)
                setCurrentStep(step + 1);
            }
        },
        [step, setCurrentStep, anexos],
    )

    const ContinueClick = () => {
        if (ValidaAnexos()) {
            avancaForm()
        }
    }

    const voltarForm = useCallback(
        () => {
            setCurrentStep(step - 1);
        },
        [step, setCurrentStep],
    )


    function removeArquivo(index: number) {
        const copia = _.cloneDeep(anexos)
        copia.splice(index, 1)
        setAnexos(copia)
    }

    useEffect(() => {
        if (anexosOriginais.length)
            setAnexos(anexosOriginais)
    }, [anexosOriginais])

    const confirmacao = (anexos.length);

    return (
        <>
            <div className={`row ${Styles.customBox}`} >
                <div className={`${Styles.botaoVoltarRow}`}>
                    <button className={`btn ${Styles.botaoVoltar}`} onClick={voltarForm}><FaLessThan /></button>
                </div>
                <div className={`col-md-12 ${Styles.customForm}`}>
                    <h2>Documentação do Representado</h2>
                    <div className="row mb-4">
                        <div className="form-group col-md-12 mt-4">
                            <div className={Styles.docsText}>
                                <label>Anexe os documentos:</label>
                                <label>- Documento que comprove a relação com o representado</label>
                                <label>- Documentação de autorização de recebimento</label>
                            </div>
                            <div {...getRootProps({ className: Styles.dropZone })}>
                                <input {...getInputProps()} />
                                <p>Arraste os arquivos ou clique para adicioná-los</p>
                            </div>
                            <aside>
                                <h4>Arquivos Selecionados</h4>
                                <ul className={Styles.filesList} >{preview}</ul>
                            </aside>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.botaoContinuarRow}`}>
                    <button
                        className={`btn ${Styles.botaoContinuar}`}
                        onClick={ContinueClick}
                        disabled={!confirmacao}
                    >
                        Continuar Solicitação
                        <FaGreaterThan />
                    </button>
                </div>
            </div>
        </>
    )
}

export default AnexosForm