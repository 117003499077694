import React from 'react'
import { FaCheckCircle, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { PagesPPR, usePagesContext } from '../../contexts/PagesPPR';
import Styles from "./styles.module.scss";


function TelaProtocolo() {
    const PagesContext = usePagesContext();
    
    return (
        <div className={`row ${Styles.customBox}`}>
            <FaCheckCircle />
            <h1>Solicitação enviada com sucesso!</h1>
            <div className={Styles.protocoloContainer}>
                <p>Protocolo da sua solicitação:</p>
                <span>{PagesContext.protocolo}</span>
            </div>
            <p>Solicitação realizada. Seu pedido passará por uma análise, que verifica o cumprimento das metas da loja e dos critérios individuais de desconto do colaborador.
                    Acompanhe sua solicitação através do protocolo.
                    O prazo de solicitação é de 01/01 a 28/02, data do pagamento 31/03.</p>
            <p>Enviamos o número do seu protocolo para o e-mail cadastrado.</p>

            <footer className={Styles.protocoloFooter}>
                <button onClick={() => PagesContext.changePage(PagesPPR.Inicio)}>
                    <FaChevronLeft/>
                    <span>Página inicial</span>
                </button>
                <button onClick={() => PagesContext.changePage(PagesPPR.AcompanharSolicitacao)}>
                    <span>Acompanhar solicitação</span>
                    <FaChevronRight/>
                </button>
            </footer>
        </div>
    )
}

export default TelaProtocolo
