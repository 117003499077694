import React, { useCallback, useEffect, useState } from 'react'
import { FaGreaterThan, FaLessThan } from 'react-icons/fa';
import { useForm } from '../../hooks/form';
import Styles from "../SolicitacaoReutilizavel/styles.module.scss";
import Select from 'react-select';
import ISelectOptions from "../../interfaces/ISelectOptions"
import api from '../../services/api';
import { formataAgencia, formataChavePix, formataDigito, formataNumeroConta, formataOperacao } from '../../utils/formataCampos';
import { validaAgencia, validaChavePix, validaNumeroConta } from '../../utils/validaCampos';
import { IDadosBancarios, tiposChavePix } from "../../interfaces/IPPRInativoSolicitacao";
import { IConfigParametrizacaoBanco } from '../../interfaces/IConfigParametrosBanco';
import { geraNotificacao } from '../../utils/funcoesNotificacao';

interface DadosBancariosProps {
    tipoSolicitante: number;
    formulario: IDadosBancarios | null;
    preencheFormulario: (dados: IDadosBancarios) => void;
}

const ContaBancariaForm: React.FC<DadosBancariosProps> = ({ formulario, preencheFormulario, tipoSolicitante }) => {
    const [banco, setBanco] = useState<ISelectOptions | null>(null);
    const [bancos, setBancos] = useState<ISelectOptions[]>([]);
    const [carregandoBancos, setCarregandoBancos] = useState(false);
    const [tipoConta, setTipoConta] = useState<ISelectOptions | null>(null);
    const tiposConta: ISelectOptions[] = [{ label: "Conta Corrente", value: 1 }, { label: "Conta Poupança", value: 2 }];
    const [agencia, setAgencia] = useState("");
    const [agenciaDigito, setAgenciaDigito] = useState("");
    const [numeroConta, setNumeroConta] = useState("");
    const [numeroDigito, setNumeroDigito] = useState("");
    const [operacao, setOperacao] = useState("");
    const [tipoContato, setTipoContato] = useState<"E" | "T">("E");
    const [opcaoRecebimentoPix, setOpcaoRecebimentoPix] = useState<ISelectOptions | null>(null);
    const [chavePix, setChavePix] = useState("");

    const [agenciaIsValid, setAgenciaIsValid] = useState(true);
    const [agenciaDigitoIsValid, setAgenciaDigitoIsValid] = useState(true);
    const [numeroContaIsValid, setNumeroContaIsValid] = useState(true);
    const [numeroDigitoIsValid, setNumeroDigitoIsValid] = useState(true);
    const [operacaoIsValid, setOperacaoIsValid] = useState(true);
    const [chavePixIsValid, setChavePixIsValid] = useState(true);
    const [bancoIsValid, setBancoIsValid] = useState(true);

    const [configBanco, setConfigBanco] = useState<IConfigParametrizacaoBanco | null>();
    const [possuiDigito,setPossuiDigito] = useState(false)
    const [bancoAtivo, setbancoAtivo] = useState(true);

    const { setCurrentStep, step } = useForm();

    const condicaoOperacao = banco ? banco.requerOperacao : false;


    const confirmacao = () : boolean => {
        return (banco) &&
                (tipoConta) &&
                (agencia && agencia !== "" && agenciaIsValid) &&
                ((possuiDigito && agenciaDigito !== "" && agenciaDigitoIsValid) || (!possuiDigito)) &&
                (numeroConta !== "" && numeroContaIsValid) &&
                (numeroDigito !== "" && numeroDigitoIsValid) &&
                ((condicaoOperacao && operacao !== "" && operacaoIsValid) || (!condicaoOperacao)) &&
                (!!opcaoRecebimentoPix ? ((chavePix !== "") && chavePixIsValid) : true) &&
                (bancoIsValid);
    }

    const avancaForm = useCallback(
        () => {
            if (confirmacao() && !!banco && !!tipoConta) {
                preencheFormulario({
                    Banco: banco, 
                    TipoConta: tipoConta, 
                    Agencia: agencia, 
                    AgenciaDigito: agenciaDigito, 
                    NumeroConta: numeroConta, 
                    NumeroDigito: numeroDigito, 
                    Operacao: operacao, 
                    TipoContato: tipoContato, 
                    TipoPix: opcaoRecebimentoPix, 
                    ChavePix: chavePix
                })
                setCurrentStep(step + 1);
            }
        },
        [banco, tipoConta, agencia, agenciaDigito, numeroConta, numeroDigito, operacao, preencheFormulario, tipoContato, opcaoRecebimentoPix, chavePix, setCurrentStep, step, confirmacao],
    )

    const voltarForm = useCallback(
        () => {
            setCurrentStep(step - 1);
        },
        [step, setCurrentStep],
    )

    useEffect(() => {
        if (formulario) {
            setBanco(formulario.Banco)
            setTipoConta(formulario.TipoConta)
            setAgencia(formulario.Agencia)
            setAgenciaDigito(formulario.AgenciaDigito)
            setNumeroConta(formulario.NumeroConta)
            setNumeroDigito(formulario.NumeroDigito)
            setOperacao(formulario.Operacao)
            setTipoContato(formulario.TipoContato)
            setOpcaoRecebimentoPix(formulario.TipoPix);
            setChavePix(formulario.ChavePix);

            if (formulario.Banco?.value)
                BuscarConfigBanco(formulario.Banco?.value);
        }
    }, [formulario])

    async function fetch() {
        setCarregandoBancos(true);

        api.get("/api/Banco/GetBancos?somenteAtivo=S")
            .then((res: any) => {
                setBancos(res.data.map((b: any) => ({ label: b.Nome, value: b.Id, CodFebraban: b.CodFebraban, requerOperacao: b.RequerOperacao, requerDigitoAgencia: b.RequerDigitoAgencia })))
            })
            .finally(() => {
                setCarregandoBancos(false);
            })
    }

    async function BuscarConfigBanco(value:number) {
        const retorno =  await api.get("api/Banco/BuscarConfigBancoID?id=" + value)
                                  .then((res: any) => {
                                      return res.data;
                                     })                       
        setConfigBanco(retorno);
        setPossuiDigito(retorno?.PossuiDigito == "S"?true:false);
        if(retorno?.PossuiDigito == "N") {
            setPossuiDigito(false);
            setAgenciaDigito("");
        } else {
            setPossuiDigito(true);
        }
        if(retorno.Ativo == "S")
        {
            setbancoAtivo(true);
            setBancoIsValid(true);
        }
        else
        {
            setbancoAtivo(false);
            geraNotificacao({
                tipo: "customizado",
                mensagem: "O banco escolhido não está disponível para pagamento do PPR, selecione outro banco ou entre em contato com o RH da filial que você trabalhou.",
                severity: "error"
            });
            setBancoIsValid(false);
        }
    }
    
    function ExecuteValidacaoAgencia(agencia :any) {
        let isvalid = validaAgencia(agencia);
        if(configBanco?.SomenteUmaAgencia == "S" && isvalid){
            if(configBanco.Agencia != agencia){
                isvalid = false; 
                geraNotificacao({
                    tipo: "customizado",
                    mensagem: "O banco selecionado possui apenas uma agência e o número correto é o " + configBanco.Agencia,
                    severity: "error"
                });
            }
        }

        return isvalid;
    }

    useEffect(() => {
        fetch();
    }, [])

    const validacaoAgencia = !agenciaDigitoIsValid ? Styles.fieldWithError : "";

    return (
        <>
            <div className={`row ${Styles.customBox}`} >
                <div className={`${Styles.botaoVoltarRow}`}>
                    <button className={`btn ${Styles.botaoVoltar}`} onClick={voltarForm}><FaLessThan /></button>
                </div>
                <div className={`col-md-12 ${Styles.customForm}`}>
                    <h2>Dados para Recebimento do PPR</h2>
                    <p>Todos os campos marcados com ' * ' são obrigatórios</p>
                    {
                        tipoSolicitante === 1 ?
                            <p className={`${Styles.obs}`}>
                                OBS: Os dados para recebimento deverão ser do ex-colaborador!
                            </p> : ""
                    }
                    <div className="row mt-4 mb-4">
                        <p><strong>Informações da conta bancária (conta corrente ou poupança)</strong></p>
                        <div className="form-group col-md-6 ">
                            <label>Banco *</label>
                            <Select
                                value={banco}
                                onChange={(value) => {
                                    setBanco(value);
                                    setOperacao("");
                                    setOperacaoIsValid(true)
                                    BuscarConfigBanco(value?.value);
                                }}
                                options={bancos}
                                isLoading={carregandoBancos}
                                isClearable
                                placeholder={"Selecione um banco"}
                                noOptionsMessage={() => "Nada a selecionar"}
                            />
                            {!bancoAtivo?
                                        <span
                                            className={Styles.errorSpan}
                                        >
                                            Banco inativo.
                                        </span> : ""
                                    }
                        </div>
                        <div className="form-group col-md-6 ">
                            <label>Tipo de Conta *</label>
                            <Select
                                value={tipoConta}
                                onChange={(value) => setTipoConta(value)}
                                options={tiposConta}
                                isClearable
                                placeholder={"Selecione o tipo "}
                                noOptionsMessage={() => "Nada a selecionar"}
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="form-group col-md-4 ">
                            <div className="d-flex">

                                <div className="flex-grow-1">

                                    <label>Agência *</label>
                                    <input className={`form-control ${!agenciaIsValid ? Styles.fieldWithError : ""}`}
                                        value={agencia} placeholder="xxxxx" maxLength={5}
                                        onChange={(data) => {
                                            setAgencia(formataAgencia(data.target.value))
                                            setAgenciaIsValid(true);
                                        }}
                                        onBlur={() => setAgenciaIsValid(ExecuteValidacaoAgencia(agencia))}
                                    />
                                    {!agenciaIsValid ?
                                        <span
                                            className={Styles.errorSpan}
                                        >
                                            Agência inválida
                                        </span> : ""
                                    }
                                </div>
                                <div className={Styles.digito}>

                                    <label>Dígito{!!possuiDigito ? "*" : ""}</label>
                                    <input className={`form-control ${possuiDigito ? validacaoAgencia : ""}`}
                                        disabled={!possuiDigito}
                                        value={agenciaDigito} placeholder="" maxLength={1}
                                        onChange={(data) => {
                                            setAgenciaDigito(formataDigito(data.target.value));
                                            setAgenciaDigitoIsValid(true);
                                        }}
                                        onBlur={() => setAgenciaDigitoIsValid(agenciaDigito.length === 1)}
                                    />
                                    {(!!possuiDigito && !agenciaDigitoIsValid) ?
                                        <span
                                            className={Styles.errorSpan}
                                        >
                                            Dígito inválido
                                        </span> : ""
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="form-group col-md-4 ">
                            <div className="d-flex">
                                <div className='flex-grow-1'>

                                    <label>N.º Conta *</label>
                                    <input className={`form-control ${!numeroContaIsValid ? Styles.fieldWithError : ""}`}
                                        value={numeroConta} placeholder="xxxxxxxxxxxx" maxLength={12}
                                        onChange={(data) => {
                                            setNumeroConta(formataNumeroConta(data.target.value));
                                            setNumeroContaIsValid(true);
                                        }}
                                        onBlur={() => setNumeroContaIsValid(validaNumeroConta(numeroConta))}
                                    />
                                    {!numeroContaIsValid ?
                                        <span
                                            className={Styles.errorSpan}
                                        >
                                            N.º Conta inválido
                                        </span> : ""
                                    }
                                </div>
                                <div className={Styles.digito}>

                                    <label>Dígito *</label>
                                    <input className={`form-control ${!numeroDigitoIsValid ? Styles.fieldWithError : ""}`}
                                        value={numeroDigito} placeholder="" maxLength={1}
                                        onChange={(data) => {
                                            setNumeroDigito(formataDigito(data.target.value));
                                            setNumeroDigitoIsValid(true);
                                        }}
                                        onBlur={() => setNumeroDigitoIsValid(numeroDigito.length === 1)}
                                    />
                                    {!numeroDigitoIsValid ?
                                        <span
                                            className={Styles.errorSpan}
                                        >
                                            Dígito inválido
                                        </span> : ""
                                    }
                                </div>
                            </div>
                        </div>
                        {condicaoOperacao &&
                            <div className="form-group col-md-3 ">
                                <label>Operação *</label>
                                <input className={`form-control ${!operacaoIsValid ? Styles.fieldWithError : ""}`}
                                    value={operacao} placeholder="xxx" maxLength={3}
                                    onChange={(data) => {
                                        setOperacao(formataOperacao(data.target.value));
                                        setOperacaoIsValid(true);
                                    }}
                                    onBlur={() => setOperacaoIsValid(operacao.length === 3)}
                                />
                                {!operacaoIsValid ?
                                    <span
                                        className={Styles.errorSpan}
                                    >
                                        Operação inválida
                                    </span> : ""
                                }
                            </div>
                        }
                    </div>
                    <div className="row mb-4">
                        <div><strong>Informações PIX</strong></div>
                        <p><small>Informe o seu PIX para que possamos utilizá-lo caso encontrarmos algum problema nas informações da conta bancária</small></p>
                        <div className="form-group col-md-4 ">
                            <label>Tipo PIX</label>
                            <Select
                                value={opcaoRecebimentoPix}
                                onChange={(value) => {
                                    setOpcaoRecebimentoPix(value);
                                    setChavePix("");
                                    setChavePixIsValid(true);
                                }}
                                options={tiposChavePix}
                                isClearable
                                placeholder={"Selecione o tipo"}
                                noOptionsMessage={() => "Nada a selecionar"}
                            />
                        </div>
                        <div className="form-group col-md-4 ">
                            <label>Chave {!!opcaoRecebimentoPix ? "*" : ""}</label>
                            <input className={`form-control ${!chavePixIsValid ? Styles.fieldWithError : ""}`}
                                value={chavePix} placeholder="Digite sua chave PIX" disabled={!(opcaoRecebimentoPix)}
                                onChange={(data) => {
                                    setChavePix(formataChavePix(data.target.value, opcaoRecebimentoPix?.value));
                                    setChavePixIsValid(true);
                                }}
                                onBlur={() => setChavePixIsValid(validaChavePix(chavePix, opcaoRecebimentoPix?.value))}
                            />
                            {!chavePixIsValid ?
                                <span
                                    className={Styles.errorSpan}
                                >
                                    Chave inválida
                                </span> : ""
                            }
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="form-group col-md-12">
                            <label>Escolha um meio de contato em caso de esclarecimento *:</label>
                            <br />
                            <div className="form-check form-check-inline">
                                <input type="radio" name="confirmacao" id="email"
                                    checked={tipoContato === "E"} onChange={() => setTipoContato("E")}
                                />
                                <label htmlFor="email">&nbsp;E-mail já informado</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input type="radio" name="confirmacao" id="telefone"
                                    checked={tipoContato === "T"} onChange={() => setTipoContato("T")}
                                />
                                <label htmlFor="telefone">&nbsp;Telefone já informado</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.botaoContinuarRow}`}>
                    <button className={`btn ${Styles.botaoContinuar}`} onClick={avancaForm} disabled={!confirmacao && true}>Continuar Solicitação <FaGreaterThan /></button>
                </div>
            </div>
        </>
    )
}

export default ContaBancariaForm
