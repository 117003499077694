import Styled from 'styled-components';

export const Body = Styled.div`
  *::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background: #949494; 
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb:hover {
    background: #7c7c7c; 
  }

  span[class$="indicatorSeparator"]{
	  display: none;
  }

  .customGridRow{
    background: transparent!important;
  }
`;

export const DivComandosPopover = Styled.div`
  background-color: #fff;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-height: 120px;
  width: 250px;
`;

export const HeaderComandosPopover = Styled.header`
  display: flex;
  height: 3rem;
  width: 100%;
  font-family: Roboto, sans-serif;
  margin-left: auto;
  margin-bottom: 1rem;
  border-bottom: 1px solid #E9ECEF;

  color: #fff;
  background-color: #5F6A7D;

  h4{
    font-size: 1.2rem;
    margin: auto 0 auto 1rem;
  }

  span{
    background: transparent;
    border: none;
    margin: auto 0.5rem auto auto;

    :hover{
      cursor: pointer;
    }
  }
`;
export const BodyComandosPopover = Styled.div`
  padding: 0.5rem 0.5rem; 
  
  div{
    gap: 10px;
    padding: 0 5px;
    display: flex;
    flex-wrap: wrap;

    button{ 
      :hover{
        filter: brightness(0.8);
        transition: filter 0.4s;
        border: 1px solid black;
      }
    }

    span{ 
      border-bottom: 1px solid #c7c8c9;
      display: flex;
      width: 100%;
      cursor: pointer;
    }
  }
`;