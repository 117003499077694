import { ValidateCPF } from "@havan/fit-components";
import { useState } from "react";
import { FaLessThan } from "react-icons/fa";
import { PagesPPR, usePagesContext } from "../../contexts/PagesPPR";
import api from "../../services/api";
import { geraNotificacao } from "../../utils/funcoesNotificacao";
import Styles from "./styles.module.scss";

interface IsValidCpf {
    isValid: boolean;
    error?: string;
}

const ReenvioProtocolo = () => {
    const [cpf, setCpf] = useState<string | null>();
    const PagesContext = usePagesContext();

    const [cpfIsValid, setCpfIsValid] = useState<IsValidCpf>();

    function formataCPF(value: string) {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function CpfOnBlur() {
        if (cpf) {
            const validaCPF = ValidateCPF(cpf)
            setCpfIsValid(validaCPF);
        }
        else {
            setCpfIsValid({ isValid: false, error: "Informe seu CPF para continuar." });
        }
    }

    function submit(){
        CpfOnBlur();
        if(cpfIsValid?.isValid){
            api.get(`/api/Solicitacao/GetProtocoloPorCPF?cpf=${cpf?.replace(/\D/g, '')}`)
            .then(response => {
                if(response.data && response.data > 0){
                    geraNotificacao({
                        tipo: "customizado",
                        mensagem: `O seu número de protocolo foi enviado para seu e-mail.`,
                        severity: "success"
                    });
                }
                else{
                    geraNotificacao({
                        tipo: "customizado",
                        mensagem: `Não foi possível reenviar o protocolo, pois o CPF informado não possui solicitações registradas para o período vigente.`,
                        severity: "error"
                    });
                }
            })
            .catch(error => {
                geraNotificacao({
                    tipo: "customizado",
                    mensagem: error.response.data,
                    severity: "error"
                });
            })
        }
        else{
            geraNotificacao({
                tipo: "customizado",
                mensagem: cpfIsValid?.error,
                severity: "error"
            });
        }
    }


    return (
        <div className={`row ${Styles.customBox}`}>
            <header>
                <button
                    className={`btn ${Styles.botaoVoltar}`}
                    onClick={() => PagesContext.changePage(PagesPPR.AcompanharSolicitacao)}
                >
                    <FaLessThan />
                </button>
            </header>
            <main className={Styles.mainContainer}>
                <h1>Digite o seu CPF</h1>
                <input
                    type="text"
                    placeholder="Somente números"
                    className="form-control"
                    value={cpf ? cpf : ""}
                    onChange={(data) => setCpf(formataCPF(data.target.value))}
                    onBlur={CpfOnBlur}
                />
                <span>
                    *Representante legal pode colocar o próprio CPF
                </span>
            </main>
            <footer className={Styles.footerContainer}>
                <button onClick={submit}>Seguir com reenvio</button>
            </footer>
        </div>
    )
}

export default ReenvioProtocolo
