import React, { createContext, ReactNode, useContext, useState } from "react";
import { ITipoSolicitante } from "../interfaces/IPPRInativoSolicitacao";

export enum PagesPPR {
  Inicio = 1,
  NovaSolicitacao,
  AcompanharSolicitacao,
  TelaDeProtocolo,
  ReenvioDeProtocolo,
  InformesRendimento
}

interface PagesPPRContextData {
  page: number;
  anoBase: number;
  protocolo: number | null;
  tipoSolicitante: ITipoSolicitante;
  funcionarioLogado: boolean;
  changePage: (page: PagesPPR) => void;
  AtualizarProtocolo: (prot: number | null) => void;
  changeTipoSolicitante: (tipo: ITipoSolicitante) => void;
  setFuncionarioLogado: (value: boolean) => void;
  DefinaAnoBaseConfiguracao: (ano: number) => void;
}

interface PagesPPRProviderProps {
  children: ReactNode;
}

export const PagesPPRContext = createContext({} as PagesPPRContextData);

export function usePagesContext() {
  return useContext(PagesPPRContext);
}

export function PagesPPRProvider({ children }: PagesPPRProviderProps) {
  const [page, setPage] = useState(PagesPPR.Inicio);
  const [protocolo, setProtocolo] = useState<number | null>(null);
  const [anoBase, setAnoBase] = useState<number>(0);
  const [tipoSolicitante, setTipoSolicitante] = useState<ITipoSolicitante>(1);
  const [funcionarioLogado, setFuncionarioLogado] = useState(false);

  function changeTipoSolicitante(tipo: ITipoSolicitante) {
    setTipoSolicitante(tipo);
  }

  function changePage(newPage: PagesPPR) {
    setPage(newPage);
  }

  function AtualizarProtocolo(prot: number | null) {
    setProtocolo(prot);
  }

  function DefinaAnoBaseConfiguracao(ano: number) {
    setAnoBase(ano);
  }

  return (
    <PagesPPRContext.Provider
      value={{
        page,
        anoBase,
        protocolo,
        tipoSolicitante,
        funcionarioLogado,
        changePage,
        AtualizarProtocolo,
        changeTipoSolicitante,
        setFuncionarioLogado,
        DefinaAnoBaseConfiguracao
      }}
    >
      {children}
    </PagesPPRContext.Provider>
  )
}