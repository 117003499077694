import React, { useEffect, useState } from 'react'
import Styles from "../SolicitacaoReutilizavel/styles.module.scss";
import { geraNotificacao } from "../../utils/funcoesNotificacao";
import api from '../../services/api';
import { IDadosIdentificacao } from '../../interfaces/IPPRInativoSolicitacao';
import Collapse from 'react-bootstrap/Collapse'
import LoadingModal from '../../components/LoadingModal';
import { GrDocumentPdf } from "react-icons/gr";
import { Button } from "@material-ui/core";
import { AESEncryptMessage } from '../../utils/encrypt';
import { Else, If, Then } from "react-if";

interface ConfirmacaoProps {
    formulario: IDadosIdentificacao | null;
}

interface InformeRendimento {
    Rendimento: number;
    NomeArquivo: string;
    AnoReferencia: number;
    Url: string;
    Id: number;
    Arquivo: string
}

const Confirmacao: React.FC<ConfirmacaoProps> = ({ formulario }) => {
    const [openExColaborador, setOpenExColaborador] = useState(true);

    const [carregandoInformes, setCarregandoInformes] = useState(false);

    const [informes, setInformes] = useState<InformeRendimento[]>([]);

    useEffect(() => {
        if (formulario) {

            const cpfBusca = AESEncryptMessage(formulario.Cpf.replace(/\D/g, ''));
            const data = {
                CPF: cpfBusca,
                UltimaFilial: formulario.UnidadeNegocio?.value,
                UltimoCargo: formulario.UltimoCargo?.value,
                NomeMae: formulario.NomeMae,
                Nome: formulario.Nome
            }

            setCarregandoInformes(true);
            api.post("api/Rendimento/ConsultaRendimento", data)
                .then((res: any) => {
                    if (res === '' || res === undefined || res.data === undefined || res.data === '') {
                        geraNotificacao({
                            tipo: "customizado",
                            mensagem: "Ocorreu uma falha ao obter os arquivos de informe de rendimentos (Retorno inválido). Por favor, entre em contato com o RH para verificar o motivo!",
                            severity: "error"
                        });
                    } else if (res?.data.length > 0) {
                        setInformes(res.data)
                    }
                })
                .catch((erro: any) => {
                    geraNotificacao({
                        tipo: "customizado",
                        mensagem: erro?.response?.data ?? "Ocorreu um erro ao consultar os arquivos de informe de rendimentos. Por favor, avise-nos para avaliar este problema!",
                        severity: "error"
                    });
                })
                .finally(() => setCarregandoInformes(false));
        }

    }, [formulario])

    return (
        <>
            <div className={`row ${Styles.customBox}`} >
                <div className={`col-md-12 ${Styles.customForm}`}>
                    <h2>Informe de rendimentos disponíveis</h2>

                    <div className="row mb-4 mt-4">
                        <div className={Styles.dadosBox}>
                            <label className={Styles.dadosLabel}
                                onClick={() => setOpenExColaborador(!openExColaborador)}
                                aria-controls="excolaborador"
                                aria-expanded={openExColaborador}
                            >Dados do Ex-colaborador</label>
                            <Collapse in={openExColaborador} >
                                <div id="excolaborador">
                                    <div className={Styles.dadosRow}>
                                        <div>Nome Completo: </div>
                                        <div className={Styles.capitalize}>{formulario?.Nome}</div>
                                    </div>
                                    <div className={Styles.dadosRow}>
                                        <div>CPF:</div>
                                        <div>{formulario?.Cpf}</div>
                                    </div>
                                    <div className={Styles.dadosRow}>
                                        <div>E-mail:</div>
                                        <div>{formulario?.Email}</div>
                                    </div>
                                    <div className={Styles.dadosRow}>
                                        <div>Celular:</div>
                                        <div>{formulario?.Telefone}</div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </div>

                    <If condition={informes !== undefined && informes.length > 0}>
                        <Then>
                            <div className="row mb-4 mt-4">
                                <div className="col-md-4">
                                    <p>Documentos disponíveis</p>    
                                    <div className="d-flex justify-content-between">
                                        {informes.map(informe => (
                                            <div key={informe.Id} className="d-flex flex-column">
                                                <span style={{ marginTop: 8 }}>{informe.NomeArquivo}</span>
                                                <Button style={{ background: "#e9fafc" }} target="_blank" href={informe.Url}>
                                                    <GrDocumentPdf /> <span style={{ marginLeft: 5, marginTop: 3 }}>Visualizar</span>
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Then>
                        <Else>
                            <div className='row' style={{marginBottom: '1.5rem'}}>
                                <span className='col'>Não foi encontrado arquivos de informes de rendimentos disponíveis no momento!</span>
                            </div>
                        </Else>
                    </If>                               
                </div>
            </div>

            <LoadingModal isLoading={carregandoInformes} />
        </>
    )
}

export default Confirmacao