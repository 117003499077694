import { ReactNode } from 'react';
import { Container, CurrentChild, IconContent, StepContainer } from './styles';

type ProgressBarProps = {
  children?: ReactNode[];
  currentStep: number;
  labels?: string[];
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
  children,
  currentStep,
  labels = [],
}) => {
  return (
    <Container>
      {children?.map((currentChild, index) => (
        <CurrentChild key={index} active={currentStep >= index}>
          <StepContainer>
            {labels && <p> {labels[index]} </p>}
            <IconContent
              active={currentStep >= index}
              current={currentStep === index}
            >
              {currentChild}
            </IconContent>
          </StepContainer>
        </CurrentChild>
      ))}
    </Container>
  );
};
