import React, { useCallback, useEffect, useState } from 'react'
import { FaGreaterThan, FaLessThan } from 'react-icons/fa';
import { useForm } from '../../hooks/form';
import Styles from "./styles.module.scss";
import CodigoVerificacao from "../../components/CodigoVerificacao";
import { PagesPPR, usePagesContext } from '../../contexts/PagesPPR';
import { geraNotificacao } from '../../utils/funcoesNotificacao';
import api from '../../services/api';
import Select from 'react-select';
import ISelectOptions from "../../interfaces/ISelectOptions";
import "./styles.css"
import { formataCPF, formataNome, formataTelefone } from '../../utils/formataCampos';
import { validarNome, validarNomeMae, validarUltimoCargo, validarUnidadeNegocio, ValidateCPF, ValidateEmail, ValidatePhone } from '../../utils/validaCampos';
import { confirmaCodigo, enviaCodigo } from '../../utils/envioCodigo';
import { ConfirmacaoContato, IDadosIdentificacao, IOrigemSms } from '../../interfaces/IPPRInativoSolicitacao';
import { AESEncryptMessage } from '../../utils/encrypt';
import LoadingModal from '../../components/LoadingModal';
import { EnumTipoSolicitacao } from '../../enums/EnumTipoSolicitacao';

interface DadosIniciaisProps {
    tipoSolicitacao: EnumTipoSolicitacao,
    formulario: IDadosIdentificacao | null,
    preencheFormulario: (dados: IDadosIdentificacao) => void;
}

interface IsValidCpf {
    isValid: boolean;
    error?: string;
}

const ExColaboradorForm: React.FC<DadosIniciaisProps> = ({ tipoSolicitacao, formulario, preencheFormulario }) => {
    const PagesContext = usePagesContext();
    const solicitacaoExcolaborador = (PagesContext.tipoSolicitante === 1);

    const [cpf, setCpf] = useState("");
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [tipoConfirmacao, setTipoConfirmacao] = useState<ConfirmacaoContato>("E")
    const [nomeMae, setNomeMae] = useState("");
    const [unidadeNegocio, setUnidadeNegocio] = useState<ISelectOptions | null>(null);
    const [unidadesNegocio, setUnidadesNegocio] = useState<ISelectOptions[]>([]);
    const [ultimoCargo, setUltimoCargo] = useState<ISelectOptions | null>(null);
    const [ultimosCargos, setUltimosCargos] = useState<ISelectOptions[]>([]);

    const [carregandoUnidades, setCarregandoUnidades] = useState(false);
    const [carregandoCargos, setCarregandoCargos] = useState(false);
    const [carregandoFormulario, setCarregandoFormulario] = useState(false);

    const [nomeIsValid, setNomeIsValid] = useState<boolean>(true);
    const [cpfIsValid, setCpfIsValid] = useState<IsValidCpf>();
    const [emailIsValid, setEmailIsValid] = useState<boolean>(true);
    const [nomeMaeIsValid, setNomeMaeIsValid] = useState<boolean>(true);
    const [unidadeNegocioIsValid, setUnidadeNegocioIsValid] = useState<boolean>(true);
    const [ultimoCargoIsValid, setUltimoCargoIsValid] = useState<boolean>(true);
    const [telefoneIsValid, setTelefoneIsValid] = useState<boolean>(true);
    const nomeMaxLength = 100;


    const [openModal, setOpenModal] = useState(false);

    const { setCurrentStep, step } = useForm();

    const confirmacao = (
        (!!cpf && cpfIsValid?.isValid) &&
        (!!nome && nomeIsValid) &&
        (nomeMae ? (!!nomeMae && nomeMaeIsValid) : true) &&
        (!!unidadeNegocio && unidadeNegocioIsValid) &&
        (!!ultimoCargo && ultimoCargoIsValid) &&
        (((!solicitacaoExcolaborador && email.length > 0) || solicitacaoExcolaborador) ? (!!email && emailIsValid) : true) &&
        (((!solicitacaoExcolaborador && telefone.length > 0) || solicitacaoExcolaborador) ? (!!telefone && telefoneIsValid) : true) &&
        ((solicitacaoExcolaborador) ? (!!tipoConfirmacao) : true)
    );

    const avancaForm = useCallback(
        async () => {
            if (confirmacao && !!unidadeNegocio && !!ultimoCargo) {
                preencheFormulario({
                    Cpf: cpf, 
                    Nome: nome, 
                    Email: email, 
                    Telefone: telefone, 
                    NomeMae: nomeMae, 
                    UnidadeNegocio: unidadeNegocio, 
                    UltimoCargo: ultimoCargo, 
                    TipoConfirmacao: tipoConfirmacao
                })
                setCurrentStep(step + 1);
            }
        },
        [step, setCurrentStep, preencheFormulario, cpf, nome, email, telefone, tipoConfirmacao, nomeMae, unidadeNegocio, ultimoCargo, confirmacao]
    )

    const VoltarForm = () => {
        if(tipoSolicitacao === EnumTipoSolicitacao.PPR_INATIVO) 
            setCurrentStep(step - 1);
        else if (tipoSolicitacao === EnumTipoSolicitacao.INFORME_DE_RENDIMENTOS)
            PagesContext.changePage(PagesPPR.Inicio)
    }

    async function validarCampos() {
        let erros = [];
        const isValid = ValidateCPF(cpf);
        if (!isValid) {
            erros.push("CPF inválido");
        }
        else if (tipoSolicitacao === EnumTipoSolicitacao.PPR_INATIVO) {
            await api.get(`/api/Solicitacao/ExisteSolicitacaoMesmoCPF?cpf=${AESEncryptMessage(cpf.replace(/\D/g, ''))}&anoBase=${PagesContext.anoBase}`)
                .then(res => {
                    if (res.data) {
                        erros.push("Já existe uma solicitação em andamento para esse CPF.");
                    }
                });
        }

        if (!emailIsValid && solicitacaoExcolaborador) {
            erros.push("E-mail inválido");
        }

        if (!telefoneIsValid && solicitacaoExcolaborador) {
            erros.push("Telefone inválido");
        }

        if (!nomeIsValid) {
            erros.push("Nome inválido");
        }

        if (!nomeMaeIsValid) {
            erros.push("Nome da Mãe inválido");
        }

        if (erros.length > 0) {
            geraNotificacao({
                tipo: "customizado",
                mensagem: erros.join(", "),
                severity: "error"
            });
            return false;
        }

        return true;
    }

    const reenviaCodigo = () => {
        if(tipoSolicitacao === EnumTipoSolicitacao.PPR_INATIVO)
            enviaCodigo(tipoConfirmacao, telefone, email, nome, cpf);
        else if (tipoSolicitacao === EnumTipoSolicitacao.INFORME_DE_RENDIMENTOS)
            enviaCodigo(tipoConfirmacao, telefone, email, nome, cpf, IOrigemSms.INFORME_DE_RENDIMENTOS, 3);
    }

    const submit = (codigo: string) => {
        return confirmaCodigo(codigo, cpf, avancaForm)
    }

    async function FetchOpcoes(cpfParam: string) {
        const cpfBusca = AESEncryptMessage(cpfParam.replace(/\D/g, ''))
        if (!cpfBusca) return;

        let cpfinvalido = false;
        setCarregandoCargos(true);

        const identificadorApi = tipoSolicitacao === EnumTipoSolicitacao.INFORME_DE_RENDIMENTOS ? "Rendimento" : "AutorizadosPPR";

        await api.get(`api/${identificadorApi}/GetOpcoesCargo?cpf=${cpfBusca}&dataBase=${PagesContext.anoBase}`)
            .then((res: any) => {
                setUltimosCargos(res.data.map((filial: any) => ({ label: filial.NomeCargo, value: filial.CodigoCargo })));
            })
            .catch((erro) => {
                setUltimosCargos([]);
                setUltimoCargo(null);
                cpfinvalido = true;
                geraNotificacao({
                    tipo: "customizado",
                    mensagem: erro.response.data.toString(),
                    severity: "error"
                });
            })
            .finally(() => setCarregandoCargos(false))

        if (!cpfinvalido) {
            setCarregandoUnidades(true);
            await api.get(`api/${identificadorApi}/GetOpcoesFilial?cpf=${cpfBusca}&dataBase=${PagesContext.anoBase}`)
                .then((res: any) => {
                    setUnidadesNegocio(res.data.map((filial: any) => ({ label: filial.NomeFilial, value: filial.CodigoFilial })));
                })
                .catch((erro) => {
                    setUnidadesNegocio([]);
                    setUnidadeNegocio(null);
                    cpfinvalido = true;
                    geraNotificacao({
                        tipo: "customizado",
                        mensagem: erro.response.data.toString(),
                        severity: "error"
                    });
                })
                .finally(() => setCarregandoUnidades(false));
        }

        if (cpfinvalido)
            setCpfIsValid({ isValid: false, error: "CPF inválido" });
        else if (tipoSolicitacao === EnumTipoSolicitacao.PPR_INATIVO)
            api.get(`/api/Solicitacao/ExisteSolicitacaoMesmoCPF?cpf=${cpfBusca}&anoBase=${PagesContext.anoBase}`)
                .then(res => {
                    if (res.data) {
                        setCpfIsValid({ isValid: false, error: "CPF já cadastrado" });
                        geraNotificacao({
                            tipo: "customizado",
                            mensagem: "Já existe uma solicitação em andamento para esse CPF",
                            severity: "error"
                        })
                    }
                });
    }

    async function ContinueClick() {
        if (tipoSolicitacao === EnumTipoSolicitacao.INFORME_DE_RENDIMENTOS)
            await ContinueClickInforme();
        else if (tipoSolicitacao === EnumTipoSolicitacao.PPR_INATIVO)
            await ContinueClickPPR();
    }

  async function ContinueClickPPR() {
    if (!validarCampos()) return;

    const params = new URLSearchParams({
      cpf: AESEncryptMessage(cpf.replace(/\D/g, "")) ?? "",
      nomeMae: nomeMae,
      unidadeNegocio: unidadeNegocio?.value ?? "",
      codigoUltimoCargo: ultimoCargo?.value ?? "",
      anoBase: `${PagesContext.anoBase}`,
      nomeExColaborador: nome,
    });

    setCarregandoFormulario(true);

    await api
      .get(`/api/AutorizadosPPR/ValidaCpf?${params.toString()}`)
      .then((res: any) => {
        if (res?.data?.length > 0) {
          geraNotificacao({
            tipo: "customizado",
            mensagem: res.data,
            severity: "info",
          });
        }

        if (solicitacaoExcolaborador) {
          enviaCodigo(tipoConfirmacao, telefone, email, nome, cpf);
          setOpenModal(true);
        } else {
          avancaForm();
        }
      })
      .catch((erro: any) => {
        geraNotificacao({
          tipo: "customizado",
          mensagem: erro.response.data,
          severity: "error",
        });
      })
      .finally(() => {
        setCarregandoFormulario(false);
      });
  }

  async function ContinueClickInforme() {	
    if (!validarCampos()) return;	
    const cpfBusca = AESEncryptMessage(cpf.replace(/\D/g, ''));	
    const data = {	
        CPF: cpfBusca,	
        UltimaFilial: unidadeNegocio?.value,	
        UltimoCargo: ultimoCargo?.value,	
        NomeMae: nomeMae,	
        Nome: nome	
    }	
    setCarregandoFormulario(true);	
    await api.post("api/Rendimento/ValidaDadosColaborador", data)	
        .then((res: any) => {
            if (res.data) {	
                // Envia codigo de confirmação	
                enviaCodigo(tipoConfirmacao, telefone, email, nome, cpf, IOrigemSms.INFORME_DE_RENDIMENTOS, 3);	
                setOpenModal(true);	
            }	
        })	
        .catch((erro: any) => {	
            geraNotificacao({	
                tipo: "customizado",	
                mensagem: erro.response.data,	
                severity: "error"	
            });	
        })	
    setCarregandoFormulario(false);	
}

async function CpfOnBlur() {
    
    if (ValidateCPF(cpf)) {
        FetchOpcoes(cpf)
        setCpfIsValid({ isValid: true });
    }
    else {
        setCpfIsValid({ isValid: false, error: "CPF inválido" });
    }

}

useEffect(() => {
    setCpfIsValid({ isValid: true });

}, []);

useEffect(() => {
    if (formulario) {
        setCpf(formulario.Cpf);
        setNome(formulario.Nome);
        setEmail(formulario.Email)
        setTelefone(formulario.Telefone);
        setTipoConfirmacao(formulario.TipoConfirmacao);
        setNomeMae(formulario.NomeMae);
        setUnidadeNegocio(formulario.UnidadeNegocio);
        setUltimoCargo(formulario.UltimoCargo);
    }
}, [formulario])

const cpfEnabled = (ultimosCargos.length && unidadesNegocio.length)

const formaContato = () : string => {
    if (tipoConfirmacao === "E")
        return "email";

    if (tipoConfirmacao === "T")
        return "telefone";
    
    return "";
}

const contatoVerificacao = () : string => {
    if (tipoConfirmacao === "E")
        return email;

    if ( tipoConfirmacao === "T" )
        return telefone;

    return "";
}

return (
    <>
        <div className={`row ${Styles.customBox}`} >
            <div className={`${Styles.botaoVoltarRow}`}>
                <button className={`btn ${Styles.botaoVoltar}`} onClick={VoltarForm}><FaLessThan /></button>
            </div>
            <div className={`col-md-12 ${Styles.customForm}`}>
                <h2>Dados do Ex-Colaborador</h2>
                <p>Todos os campos marcados com ' * ' são obrigatórios</p>
                <div className="row mb-4">
                    <div className="form-group col-md-4 ">
                        <label>CPF *</label>
                        <input
                            className={`form-control ${(cpfIsValid !== undefined && !cpfIsValid?.isValid) ? Styles.fieldWithError : ""}`}
                            placeholder="Somente números"
                            maxLength={14}
                            value={cpf}
                            onChange={(data) => setCpf(formataCPF(data.target.value))}
                            onBlur={CpfOnBlur}
                        />
                        {!cpfIsValid?.isValid ?
                            <span
                                className={Styles.errorSpan}
                            >
                                {cpfIsValid?.error}
                            </span> : ""
                        }
                    </div>
                    <div className="form-group col-md-8 ">
                        <div className="d-flex justify-content-between">
                            <label>Nome completo *</label>
                            <small style={{ color: (!nomeIsValid && (nome.trim().length <= 0 || nome.trim().length > nomeMaxLength)) ? "red" : "inherit" }}>{nome.trim().length} / {nomeMaxLength}</small>
                        </div>
                        <input
                            className={`form-control ${!nomeIsValid ? Styles.fieldWithError : ""}`}
                            placeholder="Digite o nome completo"
                            value={nome}
                            onChange={(data) => {
                                setNome(formataNome(data.target.value));
                                setNomeIsValid(true);
                            }}
                            onBlur={() => setNomeIsValid(validarNome(nome, 0, nomeMaxLength))}
                        />
                        {!nomeIsValid ?
                            <span
                                className={Styles.errorSpan}
                            >
                                Nome inválido
                                {(nome.trim().split(" ").length <= 1) && ": Nome incompleto"}
                            </span> : ""
                        }
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="form-group col-md-8 ">
                        <div className="d-flex justify-content-between">
                            <label>Nome completo da mãe {!!nomeMae ? "*" : ""}</label>
                            <small style={{ color: (!nomeMaeIsValid && (nomeMae.trim().length <= 0 || nomeMae.trim().length > nomeMaxLength)) ? "red" : "inherit" }}>{nomeMae.trim().length} / {nomeMaxLength}</small>
                        </div>
                        <input
                            className={`form-control ${!nomeMaeIsValid ? Styles.fieldWithError : ""}`}
                            placeholder="Digite o nome da mãe completo"
                            value={nomeMae}
                            onChange={(data) => {
                                setNomeMae(formataNome(data.target.value));
                                setNomeMaeIsValid(true);
                            }}
                            onBlur={() => setNomeMaeIsValid(nomeMae ? validarNomeMae(nomeMae, 0, nomeMaxLength) : true)}
                        />
                        {!nomeMaeIsValid ?
                            <span
                                className={Styles.errorSpan}
                            >
                                Nome inválido
                                {(nomeMae.trim().split(" ").length <= 1) && ": Nome incompleto"}
                            </span> : ""
                        }
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="form-group col-md-6 ">
                        <label>Última filial *</label>
                        <Select
                            value={unidadeNegocio}
                            onChange={(value) => {
                                setUnidadeNegocio(value);
                                setUnidadeNegocioIsValid(validarUnidadeNegocio(value))
                            }}
                            className={!unidadeNegocioIsValid ? `select-with-error ${Styles.fieldWithError}` : ""}
                            options={unidadesNegocio}
                            isLoading={carregandoUnidades}
                            isDisabled={!(cpfEnabled && !carregandoUnidades)}
                            isClearable
                            placeholder={"Selecione a filial"}
                            noOptionsMessage={() => "Nada a selecionar"}
                        />
                        {!unidadeNegocioIsValid ?
                            <span
                                className={Styles.errorSpan}
                            >
                                Filial inválida
                            </span> : ""
                        }
                    </div>
                    <div className="form-group col-md-6 ">
                        <label>Último cargo *</label>
                        <Select
                            value={ultimoCargo}
                            onChange={(value) => {
                                setUltimoCargo(value);
                                setUltimoCargoIsValid(validarUltimoCargo(value));
                            }}
                            className={!ultimoCargoIsValid ? `select-with-error ${Styles.fieldWithError}` : ""}
                            options={ultimosCargos}
                            isLoading={carregandoCargos}
                            isDisabled={!(cpfEnabled && !carregandoCargos)}
                            isClearable
                            placeholder={"Selecione o cargo"}
                            noOptionsMessage={() => "Nada a selecionar"}
                        />
                        {!ultimoCargoIsValid ?
                            <span
                                className={Styles.errorSpan}
                            >
                                Cargo inválido
                            </span> : ""
                        }
                    </div>
                </div>

                {
                    (solicitacaoExcolaborador || tipoSolicitacao === EnumTipoSolicitacao.INFORME_DE_RENDIMENTOS) &&
                    <>
                        <div className="row mb-4">
                            <div className="form-group col-md-6 ">
                                <label>E-mail *</label>
                                <input
                                    className={`form-control ${!emailIsValid ? Styles.fieldWithError : ""}`}
                                    placeholder="e-mail@e-mail.com.br"
                                    value={email}
                                    onChange={(data) => {
                                        setEmail(data.target.value);
                                        setEmailIsValid(true);
                                    }}
                                    onBlur={() => setEmailIsValid(ValidateEmail(email))}
                                />
                                {!emailIsValid ?
                                    <span
                                        className={Styles.errorSpan}
                                    >
                                        E-mail inválido
                                    </span> : ""
                                }
                            </div>
                            <div className="form-group col-md-6 ">
                                <label>Celular (DDD + Número) *</label>
                                <input
                                    className={`form-control ${!telefoneIsValid ? Styles.fieldWithError : ""}`}
                                    placeholder="(xx) x xxxx-xxxx"
                                    maxLength={15}
                                    value={telefone}
                                    onChange={(data) => setTelefone(formataTelefone(data.target.value))}
                                    onBlur={() => setTelefoneIsValid(ValidatePhone(telefone))}
                                />
                                {!telefoneIsValid ?
                                    <span
                                        className={Styles.errorSpan}
                                    >
                                        Telefone inválido
                                    </span> : ""
                                }
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="form-group col-md-12">
                                <label>Receber código de confirmação *:</label>
                                <br />
                                <div className="form-check form-check-inline">
                                    <input type="radio" name="confirmacao" id="email"
                                        checked={tipoConfirmacao === "E"} onChange={() => setTipoConfirmacao("E")}
                                    />
                                    <label htmlFor="email">&nbsp;E-mail</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input type="radio" name="confirmacao" id="telefone"
                                        checked={tipoConfirmacao === 'T'} onChange={() => setTipoConfirmacao("T")}
                                    />
                                    <label htmlFor="telefone">&nbsp;Telefone</label>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <div className={`${Styles.botaoContinuarRow}`}>
                <button
                    className={`btn ${Styles.botaoContinuar}`}
                    onClick={ContinueClick}
                    disabled={!confirmacao || carregandoFormulario}
                >
                    Continuar Solicitação
                    <FaGreaterThan />
                </button>
            </div>
        </div>
        <LoadingModal isLoading={carregandoFormulario} />
        <CodigoVerificacao
            open={openModal}
            closeModal={() => setOpenModal(false)}
            reenviarCodigo={reenviaCodigo}
            submit={submit}
            contato={contatoVerificacao()}
            formaContato={formaContato()}
        />
    </>
)
}

export default ExColaboradorForm