import React, { useState, useEffect, useRef } from 'react'
import Popup from 'reactjs-popup';
import { FaBell, FaTrash } from "react-icons/fa";
import { Color } from '@material-ui/lab/Alert';
import PubSub from 'pubsub-js';
import { useNotification } from '../../hooks/useNotification'
import Modal from "../CustomModal";

import './styles.css';

interface InterfaceNotificacao {
  mensagem: string;
  severity: Color;
  tempo: number;
}

const Notificacoes = {
  customizado: { mensagem: "Mensagem Customizada", severity: "info", tempo: 3000 },
  sucesso: { mensagem: "Sucesso!", severity: "success", tempo: 3000 },
  erro: { mensagem: "Ocorreu um erro!", severity: "error", tempo: 3000 },
}

export interface InterfaceDataRecebida {
  tipo: keyof typeof Notificacoes;
  severity?: Color;
  mensagem?: any;
  tempo?: number;
}

const Notificacao = () => {

  const [recebido, setRecebido] = useState<InterfaceDataRecebida | null>(null);
  const notificationsHook = useNotification();

  useEffect(() => {
    const token = PubSub.subscribe("Notificacoes", (_: string, data: InterfaceDataRecebida) => {
      if (data !== null) {
        setRecebido(data)
      }
    })
    return () => {
      PubSub.unsubscribe(token);
    }
  }, [notificationsHook])

  const handleClose = () => {
    setRecebido(null);
  };

  const retornaNotificacao = (valorRecebido: InterfaceDataRecebida | null): (InterfaceNotificacao | undefined) => {
    if (valorRecebido && valorRecebido.tipo === "customizado")
      return { mensagem: valorRecebido.mensagem || "Mensagem Customizada", severity: valorRecebido.severity || "info", tempo: valorRecebido.tempo || 5000 };
    else if (valorRecebido)
      return Notificacoes[valorRecebido.tipo] as InterfaceNotificacao;

    else return undefined;
  };

  const notificacao = retornaNotificacao(recebido);

  return (
    <>
      <Modal
        open={notificacao !== undefined}
        onClose={handleClose} closable size={"sm"}
      >
        <p style={{ whiteSpace: "pre-wrap" }}>{notificacao?.mensagem.replaceAll("\\n", `\n`)}</p>
        <div className='mt-4 mb-4' style={{ textAlign: "center" }}>
          <button
            className={`btn btn-primary`}
            onClick={handleClose}
          >
            Fechar
          </button>
        </div>
      </Modal>
    </>
  )
}

export function NotificationBar() {
  const notificationsHook = useNotification();
  const [isOpen, setIsOpen] = useState(false);
  const [temaAtual, setTemaAtual] = useState('');
  const ref = useRef(null);

  function handleNotificationRemove(index: number) {
    notificationsHook.removeNotification(index);
  }

  function localStorageThemeObserver() {
    const temaAtualStorage = getTheme();

    if (!temaAtualStorage) return;

    setTemaAtual(temaAtualStorage === 'light' ? 'dark' : 'light');
  }

  const getTheme = () => {
    const temaAtualStorage = localStorage.getItem("@HavanLabs:theme");

    if (!temaAtualStorage) return;

    return temaAtualStorage;
  }

  const configuraBotaoTemaObserver = () => {
    const botaoTema = document.querySelector('[title="Alterar tema padrão"]');

    if (!botaoTema) return;

    botaoTema.addEventListener('click', localStorageThemeObserver);
  }

  useEffect(() => {
    configuraBotaoTemaObserver();

    const temaAtualStorage = getTheme();

    if (!temaAtualStorage) return;

    setTemaAtual(temaAtualStorage);
    // eslint-disable-next-line
  }, [])

  return (
    <div className={`notificationButtonNavbar ${temaAtual === 'dark' ? 'divDark' : 'divLight'}`}>
      <button
        ref={ref}
        onClick={() => setIsOpen(true)}
        className='notificationButton'
      >
        <FaBell />
      </button>
      <Popup
        open={isOpen}
        position="bottom center"
        trigger={<div ref={ref}></div>}
        closeOnDocumentClick
        onClose={() => setIsOpen(false)}
      >
        <div className={`cardPopoverNotification ${temaAtual === 'dark' ? 'scrollDark' : 'scrollLight'}`}>
          {notificationsHook.notifications ?
            <table>
              <tbody>
                {
                  notificationsHook.notifications.map((notification, index) => {

                    return (
                      <tr style={{ width: 300 }} key={index} id={index.toString()}>
                        <td style={{ width: 270 }}>{notification.mensagem}</td>
                        <td style={{ width: 30 }}>
                          <button title="Apagar notificação" onClick={() => handleNotificationRemove(index)}>
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            :
            <span>Sem novas notificações</span>
          }
        </div>
      </Popup>
    </div>
  )
}

export default Notificacao
